import { SVGProps } from 'react';

interface EyeOutlineIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const EyeOutlineIcon = ({
  width = 24,
  height = 25,
  color = '#38383E',
  ...props
}: EyeOutlineIconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 25'
    width={width}
    height={height}
    fill='none'
    {...props}
  >
    <path
      fill={color}
      d='M12 4.23c2.009 0 3.848.738 5.268 1.963l.179.156.198.177.211.194.343.323.374.36.405.396.288.286.616.621.498.51.712.737.768.804.404.427c.678.718.703 1.807.073 2.552l-.072.081-.791.835-.562.586-.701.724-.49.5-.605.606-.282.278-.397.386-.366.349-.335.31-.206.187-.192.17-.091.078a8.04 8.04 0 0 1-5.248 1.944 8.041 8.041 0 0 1-5.272-1.966l-.179-.156-.197-.177-.322-.297-.353-.335-.384-.372-.415-.409-.602-.604-.656-.67-.712-.737-.967-1.014-.204-.216a1.907 1.907 0 0 1-.072-2.553l.072-.081.978-1.028.45-.469.636-.655.398-.407.378-.381.456-.454.458-.449.283-.272.39-.367.24-.218.22-.196.103-.09a8.042 8.042 0 0 1 5.273-1.967Zm0 1.46c-1.588 0-3.087.57-4.279 1.598l-.263.233-.197.179-.322.3-.48.46-.396.39-.428.427-.46.465-.49.502-.523.54-.745.779-.577.608a.477.477 0 0 0 0 .658l.76.8.906.943.501.515.62.629.429.427.268.264.376.365.233.222.322.301.291.263.176.154a6.517 6.517 0 0 0 4.277 1.599 6.515 6.515 0 0 0 4.257-1.58l.171-.15.191-.17.206-.188.22-.205.233-.222.248-.24.399-.39.58-.58.473-.48.503-.517.72-.748.381-.4.579-.61a.477.477 0 0 0 0-.66l-.943-.991-.89-.924-.65-.663-.45-.454-.42-.416-.387-.378-.24-.231-.226-.214-.313-.29-.19-.17-.177-.155a6.516 6.516 0 0 0-4.275-1.596ZM12 8c2.418 0 4.378 2.015 4.378 4.5 0 2.486-1.96 4.5-4.378 4.5s-4.378-2.014-4.378-4.5C7.622 10.016 9.582 8 12 8Zm.048 1.5v1.8l-.003.09a1.2 1.2 0 0 1-1.115 1.107l-.082.003h-1.8l.003.13a3 3 0 1 0 2.997-3.13Z'
    />
  </svg>
);

export default EyeOutlineIcon;
