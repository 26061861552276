import { Flex, message, Typography } from 'antd';
import { CopyIcon, EyeInvisibleIcon, EyeOutlineIcon } from '../../Icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  label: string;
  value: string;
}
const KeyValueText = ({ label, value }: Props) => {
  const [showValue, setShowValue] = useState<boolean>(false);

  const { t } = useTranslation();

  const handleCopy = () => {
    if (value) {
      navigator.clipboard.writeText(value);
      message.success(t('copy'));
    }
  };
  return (
    <Flex gap={8} justify='space-between' className='w-full'>
      <Flex className='max-w-[500px] w-4/5 overflow-hidden'>
        {showValue && (
          <Typography.Text className='whitespace-nowrap text-base font-light text-customTunaPrimary'>
            {label + ':'}
          </Typography.Text>
        )}
        <Typography.Text className='text-base font-light block text-customGrayShade5 whitespace-nowrap text-ellipsis overflow-hidden'>
          {(showValue ? value : '****************************************************') ?? '---'}
        </Typography.Text>
      </Flex>

      <Flex gap={16}>
        {showValue ? (
          <EyeOutlineIcon
            width={24}
            height={24}
            className='cursor-pointer'
            onClick={() => setShowValue(!showValue)}
          />
        ) : (
          <EyeInvisibleIcon
            width={24}
            height={24}
            className='cursor-pointer'
            onClick={() => setShowValue(!showValue)}
          />
        )}
        <CopyIcon width={24} height={24} className='cursor-pointer' onClick={handleCopy} />
      </Flex>
    </Flex>
  );
};

export default KeyValueText;
