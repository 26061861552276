import React from 'react';
import { Flex, Skeleton, Typography } from 'antd';
import OrderShipmentValueCardItem from './OrderShipmentValueCardItem';
import { Trans } from 'react-i18next';
import { PaymentType, ShipmentFeeType } from '@/types';

const { Text } = Typography;

// Define the props interface
interface OrderShipmentValueCardProps {
  orderValue: number;
  shipmentFee: number;
  customShipmentFee: number;
  // walletFee: number;
  total: number;
  orderValueText: string;
  shipmentFeeText: string;
  customShipmentFeeText: string;
  // walletFeeText: string;
  totalText: string;
  currency: string;
  orderPriceLoading?: boolean;
  shipmentFeeType: ShipmentFeeType;
  paymentType: PaymentType;
}

const OrderShipmentValueCard: React.FC<OrderShipmentValueCardProps> = ({
  orderValue,
  shipmentFee,
  customShipmentFee = 0,
  customShipmentFeeText,
  // walletFee,
  total,
  orderValueText,
  shipmentFeeText,
  // walletFeeText,
  totalText,
  currency,
  orderPriceLoading,
  shipmentFeeType,
  paymentType,
}) => {
  const renderOrderShipmentDescription = () => {
    if (
      orderValue &&
      ((customShipmentFee > 0 &&
        paymentType === PaymentType.COLLECT_ON_DELIVERY &&
        shipmentFeeType === ShipmentFeeType.BY_CUSTOMER) ||
        (paymentType === PaymentType.COLLECT_ON_DELIVERY &&
          shipmentFeeType !== ShipmentFeeType.BY_CUSTOMER))
    )
      return (
        <Flex vertical className='bg-customBlueShade1 p-2 rounded-xl mt-1 mb-3'>
          {shipmentFee - customShipmentFee > 0 ? (
            <Text className={`font-thin leading-[20px] text-[14px] text-gray-600`}>
              <Trans
                i18nKey='boxyFeeDeduction'
                values={{
                  shipmentFee,
                  currency,
                  deductedAmount: shipmentFee - customShipmentFee,
                }}
                components={{ b: <b /> }}
              />
            </Text>
          ) : (
            <Text className={`font-thin leading-[20px] text-[14px] text-gray-600`}>
              <Trans
                i18nKey='boxyFeeAddition'
                values={{
                  shipmentFee,
                  currency,
                  addedAmount: total - shipmentFee,
                }}
                components={{ b: <b /> }}
              />
            </Text>
          )}
        </Flex>
      );
    return <></>;
  };

  return (
    <Flex
      vertical
      className='py-3 px-4 border border-dashed border-customGrayShade4 rounded-md mt-4'
    >
      <Flex vertical className='border-0 border-b border-customGrayShade4 border-solid'>
        <OrderShipmentValueCardItem
          value={orderValue}
          label={orderValueText}
          isLoading={orderPriceLoading}
          currency={currency}
        />

        <OrderShipmentValueCardItem
          withIcon={customShipmentFee > 0 && shipmentFeeType === ShipmentFeeType.BY_CUSTOMER}
          value={
            customShipmentFee > 0 && shipmentFeeType === ShipmentFeeType.BY_CUSTOMER
              ? customShipmentFee
              : shipmentFee
          }
          label={
            customShipmentFee > 0 && shipmentFeeType === ShipmentFeeType.BY_CUSTOMER
              ? customShipmentFeeText
              : shipmentFeeText
          }
          isLoading={orderPriceLoading}
          currency={currency}
        />
        {renderOrderShipmentDescription()}
      </Flex>

      <Flex className='flex justify-between items-center mt-3'>
        <Text className='font-bold text-base capitalize leading-[18px] text-customTunaPrimary'>
          {totalText}
        </Text>
        <Flex className='gap-x-1'>
          {orderPriceLoading ? (
            <Skeleton.Input active size='small' style={{ width: 50 }} />
          ) : (
            <>
              <Text className='font-bold text-base leading-[18px] text-customTunaPrimary'>
                {total.toLocaleString()}
              </Text>
              <Text className='font-normal text-base uppercase leading-[18px] text-customTunaPrimary'>
                {` ${currency}`}
              </Text>
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default OrderShipmentValueCard;
