import { usePreviewOrderPrice } from '@/hooksApi/useOrder';
import { useFetchPickupAddresses } from '@/hooksApi/usePickupAddress';
import {
  BasicProduct,
  Order,
  PaymentType,
  Preview,
  ProductDetails,
  ShipmentFeeType,
} from '@/types';
import {
  attachCountryCodeToPhone,
  checkPhoneNumberValidation,
  getWarehouseAddressType,
} from '@/utils';
import { FormInstance, message } from 'antd';
import { Store } from 'antd/es/form/interface';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const orderPriceDefaultValue = {
  orders_value: 0,
  orders_value_with_shipment_fee: 0,
  orders_shipment_fee: 0,
  orders_value_with_platform_shipment_fee: 0,
  orders_custom_shipment_fee: 0,
};

enum FormSectionsEnum {
  CONTACT,
  PRODUCT,
  PICKUP_ADDRESS,
  PACKAGE_SIZE,
  PAYMENT,
  PHONE_NUMBER, // not a section
}

interface SectionFields {
  sectionName: FormSectionsEnum;
  fieldNames: string[];
  isRequired?: boolean;
  validationMessage?: string;
}

const sectionFields: SectionFields[] = [
  {
    sectionName: FormSectionsEnum.CONTACT,
    fieldNames: ['name', 'address', 'province', 'region_uid', 'phoneNumberPrimary'],
    isRequired: true,
    validationMessage: 'please_fill_customer_details',
  },
  {
    sectionName: FormSectionsEnum.PRODUCT,
    fieldNames: ['products'],
    isRequired: true,
    validationMessage: 'please_add_at_least_one_product',
  },
  {
    sectionName: FormSectionsEnum.PICKUP_ADDRESS,
    fieldNames: ['saved_pick_up_address_uid'],
    isRequired: true,
    validationMessage: 'please_select_pickup_address',
  },
  { sectionName: FormSectionsEnum.PACKAGE_SIZE, fieldNames: ['isFragile', 'packageSize'] },
  {
    sectionName: FormSectionsEnum.PAYMENT,
    fieldNames: ['customShipmentFee', 'CustomerOrMerchant'],
  },
  {
    sectionName: FormSectionsEnum.PHONE_NUMBER,
    fieldNames: [],
    validationMessage: 'please_enter_valid_phone_number',
  },
];

const CREATE_PICKUP_ADDRESS_MESSAGE = 'please_create_pickup_address';

const useUpdateOrderPricePreview = (productsStore: BasicProduct[], form: FormInstance<any>) => {
  /*
   ? IMPROVEMENTS:
      debouncing onChange function of phoneNumber and name inputs 
  */

  const [customShipmentFee, setCustomShipmentFee] = useState<number | undefined>(undefined);

  const [orderPrice, setOrderPrice] = useState<Preview>(orderPriceDefaultValue);
  const [invalidFields, setInvalidFields] = useState<Set<FormSectionsEnum>>(new Set());

  const { mutate: previewOrderPrice, isLoading: orderPriceLoading } = usePreviewOrderPrice();
  const { data: pickupAddressesData } = useFetchPickupAddresses();

  const { t } = useTranslation();

  const getInvalidMessage = (message?: string) => {
    const pickupAddressErrorMessage =
      sectionFields[FormSectionsEnum.PICKUP_ADDRESS].validationMessage;
    if (message !== pickupAddressErrorMessage) return message;
    const pickupWarehouseList = getWarehouseAddressType(pickupAddressesData?.object.items);
    return pickupWarehouseList.length === 0
      ? CREATE_PICKUP_ADDRESS_MESSAGE
      : pickupAddressErrorMessage;
  };

  const transformValuesToOrder = (values: Store, ready_to_pick_up?: boolean) => {
    const primaryPhoneNumber = attachCountryCodeToPhone(
      values?.phoneNumberPrimary,
      values?.countryCodePrimary,
    );

    const secondaryPhoneNumber = attachCountryCodeToPhone(
      values?.secondaryPhoneNumber,
      values?.countryCodeSecondary,
    );

    const productList: ProductDetails[] = values.products ?? productsStore ?? [];
    const orderData: Order = {
      fee_customer_payable:
        values?.CustomerOrMerchant === ShipmentFeeType.BY_CUSTOMER
          ? (values.customShipmentFee ?? customShipmentFee)
          : null,
      is_fragile: values?.isFragile || false,
      ready_to_pick_up: !!ready_to_pick_up,
      shipment_fee_type: values?.CustomerOrMerchant || ShipmentFeeType.BY_MERCHANT,
      payment_type: values?.CodOrPrepaid || PaymentType.COLLECT_ON_DELIVERY,
      pick_up_type: values?.pickupOrDropoff || 'PICK_UP',
      description: values?.deliveryInstruction || '',
      custom_id: values?.orderId || '',
      products: productList.map((product) => ({
        title: product?.title || '',
        price: Number(product?.price) || 0,
        quantity: Number(product?.quantity) || 1,
      })),
      saved_pick_up_address_uid: values?.saved_pick_up_address_uid || '',
      size: values?.packageSize || 'S',
      contact: {
        full_name: values?.name || '',
        address_text: values?.address || '',
        phone: primaryPhoneNumber ?? '',
        additional_phones: secondaryPhoneNumber ? [secondaryPhoneNumber] : [],
        region_uid: values?.region_uid || '',
      },
    };
    if (values.freezed_pick_up_address_title) {
      // if freezed_pick_up_address_title has value =SO=> the order status is not new or undefined

      //setting pick_up_address_uid
      orderData.pick_up_address_uid = values?.saved_pick_up_address_uid || '';
    } else {
      //setting saved_pick_up_address_uid
      orderData.saved_pick_up_address_uid = values?.saved_pick_up_address_uid || '';
    }
    return orderData;
  };

  const getInvalidSections = (orderPayload: Store, checkProduct?: boolean) => {
    const invalidSections: FormSectionsEnum[] = [];

    const primaryPhoneNumber = attachCountryCodeToPhone(
      orderPayload?.phoneNumberPrimary,
      orderPayload?.countryCodePrimary,
    );

    const isPhoneValid = checkPhoneNumberValidation(
      orderPayload?.phoneNumberPrimary,
      orderPayload?.countryCodePrimary,
    );

    const isContactValid = !!(
      orderPayload?.name &&
      primaryPhoneNumber &&
      orderPayload?.address &&
      orderPayload?.region_uid
    );
    const isPickupAddressValid =
      !!orderPayload.saved_pick_up_address_uid || !!orderPayload.freezed_pick_up_address_title;
    const isProductValid = (orderPayload.products ?? productsStore ?? []).length > 0;

    if (!isContactValid) invalidSections.push(FormSectionsEnum.CONTACT);
    if (!isPickupAddressValid) invalidSections.push(FormSectionsEnum.PICKUP_ADDRESS);
    if (!isProductValid && checkProduct) invalidSections.push(FormSectionsEnum.PRODUCT);
    // if (!isPhoneValid) invalidSections.push(RequiredFieldsEnum.PHONE_NUMBER);

    return invalidSections;
  };

  const getPreviousInvalidSections = (changedSectionIndex: number, _formValues: Store) => {
    const invalidSectionsMessage: FormSectionsEnum[] = [];

    const formValues: Store = {
      ..._formValues,
      products: _formValues.products?.length > 0 ? _formValues.products : undefined,
    };

    //if is required section => check
    for (let index = 0; index <= changedSectionIndex; index++) {
      //
      const section = sectionFields[index];
      if (section.validationMessage) {
        // if !!validationMessage => is required
        const isValid = section.fieldNames.every((field) => {
          return !!formValues[field];
        });
        if (!isValid) invalidSectionsMessage.push(section.sectionName);
      }
    }
    if (changedSectionIndex) {
      const isPrimaryPhoneValid = checkPhoneNumberValidation(
        formValues?.phoneNumberPrimary,
        formValues?.countryCodePrimary,
      );
      const isSecondaryPhoneValid = formValues?.secondaryPhoneNumber
        ? checkPhoneNumberValidation(
            formValues?.secondaryPhoneNumber,
            formValues?.countryCodeSecondary,
          )
        : true;
      if (!isPrimaryPhoneValid || !isSecondaryPhoneValid) {
        invalidSectionsMessage.push(FormSectionsEnum.PHONE_NUMBER);
      }
    }
    return invalidSectionsMessage;
  };

  const handleValidationError = (newInvalidSection: FormSectionsEnum[]) => {
    newInvalidSection.forEach((section) => {
      const validationMessage = getInvalidMessage(sectionFields[section].validationMessage);

      if (validationMessage) message.error(t(validationMessage));
    });
  };

  const checkOrderFormValidation = (orderPayload: Store, checkingForProduct?: boolean) => {
    const invalidFieldList = getPreviousInvalidSections(checkingForProduct ? 1 : 3, orderPayload);
    handleValidationError(invalidFieldList);

    return invalidFieldList.length === 0;
  };

  const updateOrderPrice = async (formData: Store): Promise<Preview> => {
    const orderPayload = transformValuesToOrder(formData);

    return new Promise((resolve) => {
      previewOrderPrice(orderPayload, {
        onSuccess: (response) => {
          const newOrderPrice = { ...response.object };
          resolve(newOrderPrice);
          invalidFields.clear();
          setInvalidFields(invalidFields);
        },
        onError: () => {
          invalidFields.add(0);
          setInvalidFields(invalidFields);

          resolve(orderPriceDefaultValue);
        },
      });
    });
  };

  const handleChangeCustomShipmentFee = (value?: number, skipFormUpdate?: boolean) => {
    setCustomShipmentFee(value);

    if (!skipFormUpdate) {
      const formValues = form.getFieldsValue();
      handleFormUpdated({ customShipmentFee: value }, { ...formValues, customShipmentFee: value });
    }
  };

  const handleProductChanged = (products: ProductDetails[]) => {
    const formValues = form.getFieldsValue();
    handleFormUpdated({ products }, { ...formValues, products });
  };

  const setPackageSize = (size: string) => {
    const formValues = form.getFieldsValue();
    handleFormUpdated({ packageSize: size }, formValues);
  };

  const shouldUpdatePrice = (changedItem: string): boolean => {
    // recall fields: products, region_uid,province, customShipmentFee,"isFragile"

    const keysToCheck = [
      'products',
      'customShipmentFee',
      // 'province',
      'region_uid',
      'isFragile',
      'packageSize',
      'saved_pick_up_address_uid',
    ];
    return (
      keysToCheck.includes(changedItem) ||
      (!!customShipmentFee && changedItem === 'CustomerOrMerchant')
    );
  };

  const handleFormUpdated = async (changedValues: any, allValues: Store) => {
    if (Object.keys(allValues).length === 0) return;

    const formValues = { ...allValues, products: allValues.products ?? productsStore };
    const changedItem = Object.keys(changedValues)[0];
    let newOrderPrice = orderPriceDefaultValue;

    const currentInvalidFields = getInvalidSections(formValues, true);
    const shouldBeCalled = shouldUpdatePrice(changedItem);

    //2nd scenario =>  if we should update but also we had invalid fields => if user changed the invalid field=> update

    if (shouldBeCalled) {
      if (currentInvalidFields.length === 0) {
        // formData is valid
        newOrderPrice = await updateOrderPrice(formValues);
      } else {
        // if we should update but formData is invalid field => if user changed the invalid field=> update
        setInvalidFields(new Set(currentInvalidFields));

        const changedSectionIndex = sectionFields.findIndex((item) =>
          item.fieldNames.includes(changedItem),
        );
        const previousInvalidSectionsMessage = getPreviousInvalidSections(
          changedSectionIndex,
          formValues,
        );

        if (changedSectionIndex) handleValidationError(previousInvalidSectionsMessage);
      }
      setOrderPrice(newOrderPrice);
    } else if (currentInvalidFields.length === 0 && invalidFields.size > 0) {
      newOrderPrice = await updateOrderPrice(formValues);
      setOrderPrice(newOrderPrice);
    }
  };

  return {
    orderPrice,
    setOrderPrice,
    orderPriceLoading,
    handleFormUpdated,
    customShipmentFee,
    setCustomShipmentFee: handleChangeCustomShipmentFee,
    setPackageSize,
    transformValuesToOrder,
    checkOrderFormValidation,
    handleProductChanged,
  };
};

export default useUpdateOrderPricePreview;
