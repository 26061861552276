import { SVGProps } from 'react';

interface CloseIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const ImportIcon = ({ width = 18, height = 13, color = '#38383E', ...props }: CloseIconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...props}>
    <path
      fill={color}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.6251 9.74123L11.7458 8.62063C11.9898 8.37673 12.3857 8.37673 12.6296 8.62063C12.8736 8.86453 12.8736 9.26056 12.6296 9.50446L10.4421 11.692C10.1979 11.9361 9.80231 11.9361 9.55835 11.692L7.3706 9.50446C7.12692 9.26056 7.12692 8.86453 7.3706 8.62063C7.61456 8.37673 8.01071 8.37673 8.25467 8.62063C8.25467 8.62063 8.87418 9.24022 9.37509 9.74123V3.75013C9.37509 3.40507 9.6552 3.12512 10.0002 3.12512C10.345 3.12512 10.6251 3.40507 10.6251 3.75013V9.74123ZM14.0626 7.50003C13.7178 7.50003 13.4377 7.21995 13.4377 6.87503C13.4377 6.5301 13.7178 6.25002 14.0626 6.25002H16.6349C17.4247 6.25002 18.066 6.89127 18.066 7.68094V14.1286C18.066 14.9182 17.4247 15.5595 16.6349 15.5595H3.38023C2.59046 15.5595 1.94922 14.9182 1.94922 14.1286V7.68094C1.94922 6.89127 2.59046 6.25002 3.38023 6.25002H5.9376C6.28265 6.25002 6.56275 6.5301 6.56275 6.87503C6.56275 7.21995 6.28265 7.50003 5.9376 7.50003H3.38023C3.28028 7.50003 3.19924 7.58111 3.19924 7.68094V14.1286C3.19924 14.2284 3.28028 14.3095 3.38023 14.3095H16.6349C16.7349 14.3095 16.8159 14.2284 16.8159 14.1286V7.68094C16.8159 7.58111 16.7349 7.50003 16.6349 7.50003H14.0626Z'
    />
  </svg>
);
export default ImportIcon;
