import { setDayjsLanguage } from '@/dayjsSetup';
import { LanguageStore } from '@/types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useLanguageStore = create<LanguageStore>()(
  persist(
    (set) => ({
      language: 'ar',
      setLanguage: (lang) => {
        setDayjsLanguage(lang === 'en' ? 'en' : 'ar');
        set({ language: lang });
      },
    }),
    {
      name: 'language-storage',
      getStorage: () => {
        const { language } = JSON.parse(localStorage.getItem('language-storage') ?? '').state;
        setDayjsLanguage(language === 'en' ? 'en' : 'ar');

        return localStorage;
      },
    },
  ),
);

export default useLanguageStore;
