import { useState, useRef } from 'react';
import { message } from 'antd';
import type { Store } from 'antd/es/form/interface';
import type { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import type { InputRef } from 'antd';
import { useTranslation } from 'react-i18next';
import { LOCAL_AR, LOCAL_EN, ROUTE } from '@/config';
import { useUserStore } from '@/stores';
import { useVerifyOtp } from '@/hooksApi';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '@/stores/authStore';
import type { MenuInfo } from 'rc-menu/lib/interface';
import useLanguageStore from '@/stores/useLanguageStore';

const useVerify = () => {
  const [otp, setOtp] = useState(['', '', '', '']); // Array to hold OTP digits
  const [errors, setErrors] = useState([false, false, false, false]); // Array to hold OTP errors
  const inputRefs = useRef<Array<InputRef | null>>([]); // Refs for input fields
  // const [direction, setDirection] = useState('ltr');
  const { i18n, t } = useTranslation();
  const navigation = useNavigate();
  const setToken = useAuthStore((state) => state.setToken);
  const { language, setLanguage } = useLanguageStore();

  const { mutate: verifyOtp, isLoading } = useVerifyOtp();

  const hash = useUserStore((state) => state.hash);

  const onFinish = (values: Store) => {
    if (values.otp && values.otp.length === 4 && hash) {
      verifyOtp(
        {
          hash,
          password: values.otp,
        },
        {
          onSuccess: (data) => {
            {
              /* V0 remove */
            }
            //TODO: identifyUser
            navigation(ROUTE.ORDERS);
            setToken(data?.object?.access_token);
          },
        },
      );
    }
  };

  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    console.warn('Failed:', errorInfo);
  };

  const handleOtpChange = (index: number, value: string) => {
    if (/^[0-9]*$/.test(value) && value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Validate the specific input field
      const newErrors = [...errors];
      newErrors[index] = value === '';
      setErrors(newErrors);

      // Move focus to the next input field if current one is filled
      if (value && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const validateOtp = () => {
    const newErrors = otp.map((digit) => digit === '');
    setErrors(newErrors);

    if (newErrors.some((isError) => isError)) {
      message.error('Please enter all four digits of the OTP');
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (validateOtp()) {
      onFinish({ otp: otp.join('') }); // Submit the combined OTP
    }
  };
  const toggleDirection = (e: MenuInfo) => {
    // setDirection((prevDirection) => (prevDirection === 'ltr' ? 'rtl' : 'ltr'));
    i18n.changeLanguage(i18n.language === LOCAL_EN ? LOCAL_AR : LOCAL_EN);
    const language = e.key;
    // i18n.changeLanguage(language);
    setLanguage(language);
  };
  return {
    // direction,
    otp,
    errors,
    handleOtpChange,
    handleSubmit,
    onFinishFailed,
    inputRefs, // Export refs to use in the component
    toggleDirection,
    isLoading,
  };
};

export default useVerify;
