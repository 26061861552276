//ExclamationTriangle
import { SVGProps } from 'react';

interface InfoModalIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

//TODO: needs optimization
const InfoModalIcon = ({
  width = 80,
  height = 80,
  color = 'white',
  ...props
}: InfoModalIconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...props}>
    <rect x='0.5' width='80' height='80' rx='40' fill='#1677FF' />
    <path
      d='M41.389 36.4266C41.5657 36.4266 41.709 36.5886 41.709 36.7885V49.6361C41.709 49.836 41.5657 49.998 41.389 49.998H39.629C39.4523 49.998 39.309 49.836 39.309 49.6361V39.1409H38.029C37.8523 39.1409 37.709 38.9789 37.709 38.779V36.7885C37.709 36.5886 37.8523 36.4266 38.029 36.4266H41.389ZM41.389 30.998C41.5657 30.998 41.709 31.1601 41.709 31.36V33.3504C41.709 33.5503 41.5657 33.7123 41.389 33.7123H39.629C39.4523 33.7123 39.309 33.5503 39.309 33.3504V31.36C39.309 31.1601 39.4523 30.998 39.629 30.998H41.389Z'
      fill={color}
    />
  </svg>
);

export default InfoModalIcon;
