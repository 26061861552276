import { DeleteIcon } from '@/components/Icons';
import { THEME_VARIABLE } from '@/config';
import { Col, Flex, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { WebHooksProperty } from '@/types';
import { PropertyData } from './PropertiesHeaderSection';
import { useState } from 'react';
import debounce from 'lodash.debounce';

interface Props {
  property: WebHooksProperty | PropertyData;
  handleDeleteProperty: () => void;
  handleUpdateProperty: (text: string, changedField: 'key' | 'value') => void;
}

const PropertiesItem = ({ property, handleDeleteProperty, handleUpdateProperty }: Props) => {
  const [key, setKey] = useState(property.key ?? '');
  const [value, setValue] = useState(property.value ?? '');
  const { t } = useTranslation();

  const debouncedUpdate = debounce((text: string, changedField: 'key' | 'value') => {
    handleUpdateProperty(text, changedField);
  }, 500);

  const handleInputChange = (text: string, changedField: 'key' | 'value') => {
    if (changedField === 'key') {
      setKey(text);
    } else setValue(text);
    debouncedUpdate(text, changedField);
  };

  return (
    <Row gutter={[16, 16]}>
      <Col lg={11} md={11} xs={24}>
        <Input
          placeholder={t('key')}
          value={key}
          onChange={(e) => handleInputChange(e.target.value, 'key')}
          className='border relative border-customGrayShade5 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg'
        />
      </Col>
      <Col lg={10} md={10} xs={24}>
        <Input
          placeholder={t('value')}
          value={value}
          onChange={(e) => handleInputChange(e.target.value, 'value')}
          className='border relative border-customGrayShade5 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg'
        />
      </Col>
      <Col lg={3} md={3} xs={24} className='flex justify-end'>
        <Flex
          className='border rounded-lg border-solid flex justify-center items-center h-[54px] w-[54px]'
          onClick={handleDeleteProperty}
        >
          <DeleteIcon color={THEME_VARIABLE.customTunaPrimary} />
        </Flex>
      </Col>
    </Row>
  );
};

export default PropertiesItem;
