import { THEME_VARIABLE } from '@/config';
import { Col, Flex, Input, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Card } from '../../Card';
import { Heading } from '../../Heading';
import { AddIcon, CheckOutline } from '../../Icons';
import { useCreateApiToken, useFetchApiTokens } from '@/hooksApi';
import { useState } from 'react';
import { AccessTokenResponse } from '@/types';
import KeyValueText from './KeyValueText';
import AccessTokenItem from './AccessTokenItem';

const AccessTokenSection = () => {
  const [tokenNameInput, setTokenNameInput] = useState<string>('');
  const [newTokenData, setNewTokenData] = useState<AccessTokenResponse | undefined>(undefined);

  const { t } = useTranslation();

  const { mutate: createApiToken, isLoading: isCreatingToken } = useCreateApiToken();
  const { data: tokenList } = useFetchApiTokens();

  const handleCreateToken = () => {
    createApiToken(tokenNameInput, {
      onSuccess: (data) => {
        setNewTokenData({ ...data.object });
        setTokenNameInput('');
      },
    });
  };

  return (
    <Flex vertical>
      <Heading title={t('active_personal_tokens')} fontSize='text-lg' fontWeight='font-bold' />
      <Flex vertical gap={24}>
        <Card extraClassName='mt-2 py-8'>
          <Row gutter={24}>
            <Col lg={21} md={21} xs={24}>
              <Input
                value={tokenNameInput}
                onChange={(e) => setTokenNameInput(e.target.value ?? '')}
                placeholder={t('token_name')}
                variant='borderless'
                className='border border-solid border-customGrayShade5 placeholder:capitalize placeholder:font-normal placeholder:text-base placeholder:leading-[20.48px] placeholder:text-GrayShade4 rounded-lg h-[56px]'
              />
            </Col>
            <Col lg={3} md={3} xs={24}>
              <Flex
                className='border rounded-lg border-solid border-customGrayShade5 flex justify-center items-center h-[56px] w-[56px]'
                onClick={handleCreateToken}
                style={{
                  cursor: tokenNameInput !== '' && !isCreatingToken ? 'pointer' : 'not-allowed',
                  backgroundColor:
                    tokenNameInput !== ''
                      ? THEME_VARIABLE.customYellowPrimary
                      : THEME_VARIABLE.customWhite,
                  borderColor:
                    tokenNameInput !== ''
                      ? THEME_VARIABLE.customYellowPrimary
                      : THEME_VARIABLE.GrayShade5,
                }}
              >
                {tokenNameInput !== '' ? (
                  <CheckOutline color={THEME_VARIABLE.customBlack} width={24} height={24} />
                ) : (
                  <AddIcon color={THEME_VARIABLE.customTunaPrimary} />
                )}
              </Flex>
            </Col>
          </Row>
        </Card>
        {newTokenData && (
          <Flex
            vertical
            className='bg-customGreenShade1 rounded-[20px] border-solid py-4 px-5 border-customGreen border-[1px] shadow-card-dark'
          >
            <Flex gap={10}>
              <CheckOutline color='#2BB45D' width={24} height={24} />
              <Heading
                fontWeight='font-bold'
                title={t('your_new_personal_access_token')}
                level={5}
              />
            </Flex>
            <Flex className='ps-9' gap={8} vertical>
              <KeyValueText label={t('api_key')} value={newTokenData?.api_key} />
              <KeyValueText label={t('api_secret')} value={newTokenData?.api_secret} />

              <Typography.Text className='text-base font-light text-customTunaPrimary'>
                {t('make_sure_you_save_it_no_access')}
              </Typography.Text>
            </Flex>
          </Flex>
        )}
        {tokenList?.object.items?.map((item) => (
          <AccessTokenItem name={item.name} apiKey={item.key} key={item.key} />
        ))}
      </Flex>
    </Flex>
  );
};

export default AccessTokenSection;
