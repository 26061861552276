import { Flex } from 'antd';
import { Heading } from '../Heading';
import { useTranslation } from 'react-i18next';
import { CopyIcon } from '../Icons';
import { COPY, OrderStatusEnum } from '@/config';
import Paragraph from 'antd/es/typography/Paragraph';
import { PriceWithUnit } from '../Price';
import { StatusTag } from '../Status';
import { getStatusTagData } from '@/utils/getStatus';

interface CustomerDetailsProps {
  id?: string;
  amount?: number;
  status?: OrderStatusEnum;
}

const CustomerDetails = ({
  id = '1',
  amount = 15000,
  status = OrderStatusEnum.new,
}: CustomerDetailsProps) => {
  const { t } = useTranslation();
  return (
    <Flex vertical>
      <Flex justify='space-between' align='center'>
        <Heading title={t('customer_details')} fontSize='text-lg' fontWeight='font-bold' />
        <StatusTag
          text={t(status)}
          color={getStatusTagData(status)?.tagColor || 'processing'}
          iconColor={getStatusTagData(status)?.iconColor || ''}
        />
      </Flex>
      <Flex justify='space-between' align='center' className='mt-4'>
        <Flex vertical>
          <Heading
            title={t('reference_id')}
            color='text-customGrayShade5'
            fontSize='text-sm'
            fontWeight='font-normal'
          />
          <Paragraph
            copyable={{
              icon: [<CopyIcon key={COPY} />],
            }}
          >
            #{id}
          </Paragraph>
        </Flex>
        <Flex vertical>
          <Heading
            title={t('remittance_amount')}
            color='text-customGrayShade5'
            fontSize='text-sm'
            fontWeight='font-normal'
          />
          <PriceWithUnit
            value={amount}
            subTitle={t('iqd')}
            fontSize='16px'
            fontSizeSub='text-sm'
            fontWeight='font-normal'
            fontWeightSub='font-medium'
          />
        </Flex>
        {/* <Flex vertical>
          <Heading
            title={t('estimated_delivery')}
            color='text-customGrayShade5'
            fontSize='text-sm'
            fontWeight='font-normal'
          />
          <Heading title='13 Jun 2024' fontSize='text-base' />
        </Flex> */}
        <div />
      </Flex>
    </Flex>
  );
};

export default CustomerDetails;
