import { Flex, Menu, MenuProps } from 'antd';
import { Heading } from '../Heading';
import { useTranslation } from 'react-i18next';
import { CustomSettingsCard } from '.';
import { KeyOutlineIcon, LinkOutline } from '../Icons';
import { THEME_VARIABLE } from '@/config';
import { SettingSection } from '@/pages/settings';

interface Props {
  selectedSection: string;
  handleMenuClick: MenuProps['onClick'];
}
const IntegrationCard = ({ handleMenuClick, selectedSection }: Props) => {
  const { t } = useTranslation();

  return (
    <Flex vertical className='mt-6'>
      <Heading
        upperCase
        title={t('integration')}
        fontSize='text-lg'
        fontWeight='font-bold'
        extraClassName='leading-[20.88px]'
      />
      <Menu
        className='py-4 px-5 rounded-lg shadow-card-dark mt-2'
        mode='vertical'
        selectedKeys={[selectedSection]}
        onClick={handleMenuClick}
      >
        <Menu.Item key={SettingSection.AccessToken} className='h-[64px] '>
          <CustomSettingsCard
            IconLeft={
              <KeyOutlineIcon width={24} height={24} color={THEME_VARIABLE.customTunaPrimary} />
            }
            title={t('access_tokens')}
            description={t('add_your_personal_tokens')}
            extraClassName='h-[64px] rounded-4'
            borderBottomStyle={`1px solid ${THEME_VARIABLE.customGrayShade3}`}
            leftIconBackgroundColor={
              selectedSection === SettingSection.AccessToken
                ? 'bg-customYellowPrimary'
                : 'bg-customGrayShade2'
            }
            descriptionColor={
              selectedSection === SettingSection.AccessToken
                ? 'text-customTunaPrimary'
                : 'text-customGrayShade5'
            }
          />
        </Menu.Item>
        <Menu.Item key={SettingSection.WebHook} className='h-[64px]'>
          <CustomSettingsCard
            IconLeft={
              <LinkOutline width={24} height={24} color={THEME_VARIABLE.customTunaPrimary} />
            }
            title={t('web_hooks')}
            description={t('customize_your_web_hooks')}
            extraClassName='h-[64px]'
            leftIconBackgroundColor={
              selectedSection === SettingSection.WebHook
                ? 'bg-customYellowPrimary'
                : 'bg-customGrayShade2'
            }
            descriptionColor={
              selectedSection === SettingSection.WebHook
                ? 'text-customTunaPrimary'
                : 'text-customGrayShade5'
            }
          />
        </Menu.Item>
      </Menu>
    </Flex>
  );
};

export default IntegrationCard;
