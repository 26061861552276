import { THEME_VARIABLE } from '@/config';
import { Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { Heading } from '../../Heading';
import { AddIcon } from '../../Icons';
import { useFetchWebhooks } from '@/hooksApi';
import { useState } from 'react';
import WebHookCardItem from './WebHookCardItem';
import WebHookModal from './WebhookModal/WebHookModal';

const WebHookSection = () => {
  const [webHookModalData, setWebHookModalData] = useState<string | undefined>(undefined);

  const { t } = useTranslation();

  const { data: webhookDataList } = useFetchWebhooks();

  return (
    <Flex vertical>
      <Flex justify='space-between'>
        <Heading title={t('web_hooks')} fontSize='text-lg' fontWeight='font-bold' />
        <Flex
          onClick={() => {
            setWebHookModalData('');
          }}
          className='w-[44px] cursor-pointer  h-[44px] flex justify-center items-center rounded-[10px] border border-solid border-customTunaPrimary'
        >
          <AddIcon width={24} height={24} color={THEME_VARIABLE.customTunaPrimary} />
        </Flex>
      </Flex>
      <Flex vertical gap={16}>
        {webhookDataList?.object.items?.map((item, index) => (
          <WebHookCardItem
            key={item.uid}
            {...item}
            index={index}
            setWebHookModalData={setWebHookModalData}
          />
        ))}
      </Flex>
      <WebHookModal webHookUID={webHookModalData} onClose={() => setWebHookModalData(undefined)} />
    </Flex>
  );
};

export default WebHookSection;
