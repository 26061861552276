import {
  BottomSheet,
  Card,
  CheckIcon,
  CustomerDetails,
  DriverInfo,
  ExIcon,
  Heading,
  InvoiceIcon,
  OrderTracker,
  PaymentStatus,
} from '@/components';
import { ROUTE } from '@/config';
import { Col, Flex, Layout, MenuProps, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TimelineItemProps } from '@/components/OrderTracker/OrderTracker';
import useRemittanceTimeline from '@/hooks/useRemittanceTimeline';
import { LastUpdatedCard } from '@/components/OrderDetailsCards';
import { downloadFile, formatDateByDayjs } from '@/utils';
import { fetchLabelRemittanceByUID } from '@/api';

const FinanceSingle = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { timelineItems, data, isLoading } = useRemittanceTimeline(id as string);

  const response = data?.object;

  const onClickDropdown = async () => {
    await downloadFile(
      id as string,
      fetchLabelRemittanceByUID,
      `${data?.object.platform_code}.pdf`,
    );
  };

  const menuItems: MenuProps['items'] = [
    {
      key: '1',
      label: <Heading title={t('export_to_csv')} leadingIcon={<ExIcon />} rotation={false} />,
    },
  ];

  if (isLoading) return <Spin />;

  return (
    <Layout className='relative h-fit'>
      {/* Finance Title */}
      <Row>
        <Col md={24}>
          <Flex align='center' gap={6}>
            <Heading
              routeLink={`${ROUTE.FINANCE}?activeTab="cod"`}
              title={t('cod_remittances')}
              fontSize='text-2xl'
              fontWeight='font-bold'
            />
            <Heading
              title={`#${data?.object.platform_code}`}
              fontSize='text-2xl'
              fontWeight='font-normal'
            />
          </Flex>
        </Col>
      </Row>
      {/* End of Finance Title */}

      <Row className='mt-6  mb-12' gutter={16}>
        {/* Start CUSTOMER DETAILS AND ORDERS AND DRIVER INFO*/}
        <Col lg={14} md={24} xs={24}>
          <Card>
            <DriverInfo
              assign={data?.object.driver === null ? false : true}
              driver={data?.object.driver}
            />
          </Card>
          <Card extraClassName='mb-6'>
            <CustomerDetails
              id={data?.object?.platform_code || ''}
              amount={data?.object?.amount || 0}
              status={data?.object?.status?.slug}
            />
          </Card>
          {/* <Card>
            <Heading title={t('orders')} fontSize='text-lg' fontWeight='font-bold' />
            <Space className={STYLE_SPACE} align='center'>
              <Heading title={t('related_orders')} fontSize='text-sm' />
              <Heading title='12' trailingIcon={<RightIcon />} fontWeight='font-bold' />
            </Space>
          </Card> */}
        </Col>
        {/*End CUSTOMER DETAILS AND ORDERS */}

        {/* Start REMITTANCE BREAKDOWN And LastUpdate */}
        <Col lg={10} md={24} xs={24}>
          <Card extraClassName='mb-6'>
            <PaymentStatus
              headerTitle={t('remittance_breakdown')}
              titleFirst={t('total_cod_collected')}
              //titleSecond={`${t('total_shipment_fee')} (12 x ${t('orders_item')})`}
              titleSecond={`${t('total_shipment_fee')}`}
              total={data?.object?.amount || 0}
              valueFirst={data?.object?.amount || 0}
              valueSecond={5000}
              hideShipment
            />
          </Card>
          <LastUpdatedCard
            lastUpdateText={t('last_update')}
            statusText={t(response?.status?.merchant_title || '')}
            descriptionText={t(response?.status?.title || '')}
            dateText={formatDateByDayjs(
              response?.statuses?.[0]?.pivot?.created_at,
              'dddd, DD MMMM YYYY',
            )}
          />
          <Card extraClassName='mb-6'>
            <OrderTracker timelineItems={timelineItems as TimelineItemProps[]} />
          </Card>
        </Col>
        {/* End REMITTANCE BREAKDOWN And LastUpdate */}
      </Row>

      {/* BottomSheet */}
      <Row className='sticky bottom-0'>
        <div className='relative block w-full overflow-hidden h-[90px]'>
          <BottomSheet
            isVisible={true}
            labelBtn={t('download_statement')}
            Icon={<InvoiceIcon />}
            showRecords={false}
            disableDropdown
            onSubmit={onClickDropdown}
          />
        </div>
      </Row>
      {/* End of BottomSheet */}
    </Layout>
  );
};

export default FinanceSingle;
