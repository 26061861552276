import { SVGProps } from 'react';

interface ExportIcon2Props extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const ExportIcon2 = ({
  width = 18,
  height = 13,
  color = '#38383E',
  ...props
}: ExportIcon2Props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.39051 10.1933L8.26981 11.3139C8.02584 11.5578 7.62997 11.5578 7.38601 11.3139C7.14205 11.07 7.14205 10.674 7.38601 10.4301L9.57348 8.24252C9.81772 7.99849 10.2133 7.99849 10.4573 8.24252L12.645 10.4301C12.8887 10.674 12.8887 11.07 12.645 11.3139C12.4011 11.5578 12.0049 11.5578 11.761 11.3139C11.761 11.3139 11.1414 10.6943 10.6405 10.1933V16.1844C10.6405 16.5295 10.3604 16.8094 10.0154 16.8094C9.67061 16.8094 9.39051 16.5295 9.39051 16.1844V10.1933ZM5.95302 12.4345C6.29778 12.4345 6.57789 12.7146 6.57789 13.0595C6.57789 13.4045 6.29778 13.6846 5.95302 13.6846H3.38068C2.59091 13.6846 1.94967 13.0433 1.94967 12.2536V5.80601C1.94967 5.01634 2.59091 4.37509 3.38068 4.37509L16.6354 4.37509C17.4252 4.37509 18.0664 5.01634 18.0664 5.80601V12.2536C18.0664 13.0433 17.4252 13.6846 16.6354 13.6846H14.078C13.733 13.6846 13.4529 13.4045 13.4529 13.0595C13.4529 12.7146 13.733 12.4345 14.078 12.4345H16.6354C16.7353 12.4345 16.8164 12.3535 16.8164 12.2536V5.80601C16.8164 5.70618 16.7353 5.6251 16.6354 5.6251L3.38068 5.6251C3.28073 5.6251 3.19969 5.70618 3.19969 5.80601L3.19969 12.2536C3.19969 12.3535 3.28073 12.4345 3.38068 12.4345H5.95302Z'
      fill='#38383E'
    />
  </svg>
);
export default ExportIcon2;
