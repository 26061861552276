import { axiosJSONInstance, OrderStatusEnum } from '@/config';
import { ISearch } from '@/hooks/useSearchStore';
import {
  ActivityLogResponse,
  Order,
  OrderListResponse,
  OrderPriceResponse,
  OrderResolveAction,
  OrderResolveResponse,
  OrderRtoRequest,
  OrderRtoResponse,
  OrderSingleResponse,
  OrderSingleRtoResponse,
  OrdersResponse,
  Product,
} from '@/types'; // Ensure this path is correct based on your folder structure
import { createSearchParams } from '@/utils/params';

// Fetch all orders
export const fetchOrders = async (): Promise<OrdersResponse> => {
  try {
    const response = await axiosJSONInstance.get(`/merchants/orders`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch orders:', error);
    throw error; // Rethrow the error to be caught in the useQuery onError
  }
};

// Fetch an order by UID
export const fetchOrderByUID = async (uid: string): Promise<OrderSingleResponse> => {
  const response = await axiosJSONInstance.get(`/merchants/orders/${uid}`);
  return response.data;
};

// Create a new order
export const createOrder = async (order: Order): Promise<OrdersResponse> => {
  const response = await axiosJSONInstance.post('/merchants/orders/request', order);
  return response.data;
};
// Preview order price
export const previewOrderPrice = async (order: Order): Promise<OrderPriceResponse> => {
  const response = await axiosJSONInstance.post('/merchants/orders/preview', order);
  return response.data;
};

// Update an order by UID
export const updateOrder = async (
  order: Partial<Order> & { uid: string },
): Promise<OrdersResponse> => {
  const response = await axiosJSONInstance.patch(`/merchants/orders/${order.uid}`, order);
  return response.data;
};

// Delete an order by UID
export const deleteOrder = async (uid: string): Promise<void> => {
  await axiosJSONInstance.delete(`/merchants/orders/${uid}`);
};

//Bulk Delete orders
export const bulkDeleteOrders = async (
  status?: OrderStatusEnum,
  selectedIds?: string[],
): Promise<void> => {
  const queryParams: string[] = [];

  if (status) queryParams.push(`filter_statuses[]=${status}`);

  if (selectedIds?.length) {
    const idsQuery = selectedIds.map((id) => `filter_uids[]=${id}`).join('&');
    queryParams.push(idsQuery);
  }

  const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';
  const url = `/merchants/orders/bulk-delete${queryString}`;

  await axiosJSONInstance.post(url);
};

// Cancel an order by UID
export const cancelOrder = async (uid: string): Promise<OrdersResponse> => {
  const response = await axiosJSONInstance.post(`/merchants/orders/${uid}/cancel`);
  return response.data;
};

//Bulk Cancel orders
export const bulkCancelOrders = async (
  status?: OrderStatusEnum,
  selectedIds?: string[],
): Promise<void> => {
  const queryParams: string[] = [];

  if (status) queryParams.push(`filter_statuses[]=${status}`);

  if (selectedIds?.length) {
    const idsQuery = selectedIds.map((id) => `filter_uids[]=${id}`).join('&');
    queryParams.push(idsQuery);
  }

  const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';
  const url = `/merchants/orders/bulk-cancel${queryString}`;

  await axiosJSONInstance.post(url);
};

// Add a new product to an order
export const addProductToOrder = async (
  orderUID: string,
  product: Product,
): Promise<OrdersResponse> => {
  const response = await axiosJSONInstance.post(`/merchants/orders/${orderUID}/products/`, product);
  return response.data;
};

// Delete a new product to an order
export const deleteProductFromOrder = async (
  orderUID: string,
  productUID: string,
): Promise<OrdersResponse> => {
  const response = await axiosJSONInstance.delete(
    `/merchants/orders/${orderUID}/products/${productUID}`,
  );
  return response.data;
};

// Update a product by UID for a specific order
export const updateProductByUID = async (
  orderUID: string,
  productUID: string,
  updatedProduct: Partial<Product>,
): Promise<Product> => {
  const response = await axiosJSONInstance.patch(
    `/merchants/orders/${orderUID}/products/${productUID}`,
    updatedProduct,
  );
  return response.data;
};

// Fetch Single label Pickup by UID
export const fetchSingleLabelPickupByUID = async (uid: string): Promise<File> => {
  const response = await axiosJSONInstance.get(`/merchants/orders/${uid}/pick-up-labels`, {
    responseType: 'blob',
  });
  return response.data;
};

// Fetch Bulk label Pickup by UIDs
export const fetchBulkLabelPickupByUIDs = async (data: string): Promise<File> => {
  const response = await axiosJSONInstance.get(`merchants/orders/pick-up-labels?${data}`, {
    responseType: 'blob',
  });
  return response.data;
};

// order list status

// Fetch List Order ReadyToShip
export const fetchListOrderReady = async (
  page: number,
  pageSize?: number,
  search?: ISearch,
): Promise<OrderListResponse> => {
  const searchParams = createSearchParams(page, pageSize, search);

  const response = await axiosJSONInstance.get(
    `/merchants/orders/ready-to-ship?${searchParams.toString()}`,
  );
  return response.data;
};

// fetch List Order All
export const fetchListOrders = async (
  page: number,
  pageSize?: number,
  search?: ISearch,
): Promise<OrderListResponse> => {
  const searchParams = createSearchParams(page, pageSize, search);

  const response = await axiosJSONInstance.get(`/merchants/orders?${searchParams.toString()}`);
  return response.data;
};

// Fetch List Order Inprogress
export const fetchListInprogress = async (
  page: number,
  pageSize?: number,
  search?: ISearch,
): Promise<OrderListResponse> => {
  const searchParams = createSearchParams(page, pageSize, search);

  const response = await axiosJSONInstance.get(
    `/merchants/orders/in-progress?${searchParams.toString()}`,
  );
  return response.data;
};

// Fetch List Order OnHold
export const fetchListOnHold = async (
  page: number,
  pageSize?: number,
  search?: ISearch,
): Promise<OrderListResponse> => {
  const searchParams = createSearchParams(page, pageSize, search);

  const response = await axiosJSONInstance.get(
    `/merchants/orders/on-hold?${searchParams.toString()}`,
  );
  return response.data;
};

// Fetch List Order Finished
export const fetchListFinished = async (
  page: number,
  pageSize?: number,
  search?: ISearch,
): Promise<OrderListResponse> => {
  const searchParams = createSearchParams(page, pageSize, search);

  const response = await axiosJSONInstance.get(
    `/merchants/orders/finished?${searchParams.toString()}`,
  );
  return response.data;
};

// Fetch List By Status Slug
export const fetchListByStatusSlug = async (
  slug: string,
  page: number,
  pageSize?: number,
  search?: ISearch,
): Promise<OrderListResponse> => {
  const searchParams = createSearchParams(page, pageSize, search);

  const response = await axiosJSONInstance.get(
    `/merchants/orders/status/${slug}?${searchParams.toString()}`,
  );
  return response.data;
};

// Fetch Action Required Order List
export const fetchActionRequiredOrderList = async (
  page: number,
  pageSize?: number,
  search?: ISearch,
): Promise<OrderListResponse> => {
  const searchParams = createSearchParams(page, pageSize, search);

  const response = await axiosJSONInstance.get(
    `/merchants/orders/action-required?${searchParams.toString()}`,
  );
  return response.data;
};

//Resolve order action
export const resolveActionOrder = async (
  orderId: string,
  resolveAction: OrderResolveAction,
): Promise<OrderResolveResponse> => {
  const response = await axiosJSONInstance.post(
    `/merchants/orders/${orderId}/resolve`,
    resolveAction,
  );
  return response.data;
};

//RTO request order
export const rtoRequestOrder = async (
  orderId: string,
  rtoRequest: OrderRtoRequest,
): Promise<OrderRtoResponse> => {
  const response = await axiosJSONInstance.post(
    `/merchants/orders/${orderId}/request-rto`,
    rtoRequest,
  );
  return response.data;
};

// RTO List
export const fetchOrderRTO = async (
  page: number,
  pageSize?: number,
  search?: ISearch,
): Promise<OrderListResponse> => {
  const searchParams = createSearchParams(page, pageSize, search);

  const response = await axiosJSONInstance.get(`/merchants/orders/rto?${searchParams.toString()}`);
  return response.data;
};

// RTO Order By Id
export const fetchOrderRTOById = async (uid: string): Promise<OrderSingleRtoResponse> => {
  const response = await axiosJSONInstance.get(`/merchants/rto/${uid}`);
  return response.data;
};

// Fetch an order collecting
export const fetchOrderCollecting = async (
  page: number,
  pageSize?: number,
  search?: ISearch,
): Promise<OrderListResponse> => {
  const searchParams = createSearchParams(page, pageSize, search);

  const response = await axiosJSONInstance.get(
    `merchants/orders/collecting?${searchParams.toString()}`,
  );
  return response.data;
};

// Fetch an order en-route
export const fetchOrderEnRoute = async (
  page: number,
  pageSize?: number,
  search?: ISearch,
): Promise<OrderListResponse> => {
  const searchParams = createSearchParams(page, pageSize, search);

  const response = await axiosJSONInstance.get(
    `merchants/orders/en-route?${searchParams.toString()}`,
  );
  return response.data;
};

// Fetch Bulk orders by UIDs
export const fetchBulkOrderByQuery = async (queryString: string): Promise<File> => {
  const response = await axiosJSONInstance.get(`merchants/orders/export?${queryString}`, {
    responseType: 'blob',
  });
  return response.data;
};

export const fetchOrderActivityLogByOrderUID = async (
  uid: string,
): Promise<ActivityLogResponse> => {
  const response = await axiosJSONInstance.get(`merchants/orders/${uid}/activity-logs`);

  return response.data;
};

// Fetch List Order RTO returning-origin
export const fetchListReturningOrigin = async (
  page: number,
  pageSize?: number,
  search?: ISearch,
): Promise<OrderListResponse> => {
  const searchParams = createSearchParams(page, pageSize, search);

  const response = await axiosJSONInstance.get(
    `/merchants/orders/returning-origin?${searchParams.toString()}`,
  );
  return response.data;
};

// Fetch List Order RTO returning-warehouse
export const fetchListReturningWarehouse = async (
  page: number,
  pageSize?: number,
  search?: ISearch,
): Promise<OrderListResponse> => {
  const searchParams = createSearchParams(page, pageSize, search);

  const response = await axiosJSONInstance.get(
    `/merchants/orders/returned-warehouse?${searchParams.toString()}`,
  );
  return response.data;
};
