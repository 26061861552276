import { useProgressBar } from '@/stores';
import { useQueryClient } from '@tanstack/react-query';
import { useState, useRef, useEffect } from 'react';

const useOrdersProgressBar = () => {
  // const [percent, setPercent] = useState(60);
  const { progressToastFileName, setProgressToastFileName } = useProgressBar();
  const timerRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (progressToastFileName) {
      timerRef.current = setTimeout(() => {
        setProgressToastFileName(null);
        queryClient.invalidateQueries(['orders']);
      }, 10000);
    }

    return clearTimer;
  }, [progressToastFileName]);
  // useEffect(() => {
  //   if (showToast) {
  //     timerRef.current = setInterval(() => {
  //       setPercent((prevValue) => {
  //         const newPercent = prevValue + 5;

  //         if (newPercent >= 100) {
  //           clearTimer();
  //           return 100;
  //         }
  //         return newPercent;
  //       });
  //     }, 600);
  //   } else {
  //     clearTimer();
  //   }

  //   return clearTimer;
  // }, [showToast]);

  const clearTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
    // setPercent(0);
  };

  return {
    progressToastFileName,
    setProgressToastFileName,
  };
};

export default useOrdersProgressBar;
