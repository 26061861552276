import { ReactNode } from 'react';
import { Flex, Layout, Row } from 'antd';
import { Header } from '@/components';
import { Sidebar } from '@/components/Sidebar';
import { useTranslation } from 'react-i18next';
import { LOCAL_EN } from '@/config';

interface MainProps {
  children: ReactNode;
}

const Main = ({ children }: MainProps) => {
  const { i18n } = useTranslation();

  return (
    <Layout className='min-h-screen p-6'>
      <Flex className='w-full relative'>
        <Sidebar />
        <Layout
          className={`w-[calc(100%-200px)] relative ${i18n.language === LOCAL_EN ? 'pl-6' : 'pr-6'}`}
        >
          <Flex className='mt-4 md:mt-0' vertical>
            <Header />
            {children}
          </Flex>
        </Layout>
      </Flex>
    </Layout>
  );
};

export default Main;
