import { SVGProps } from 'react';

interface CloseIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const CloseIcon = ({ width = 25, height = 24, color = '#38383E', ...props }: CloseIconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 25 24'
    width={width}
    height={height}
    fill='none'
    {...props}
  >
    <path
      fill={color}
      d='m5.804 4.059 6.785 6.85 6.783-6.85a.2.2 0 0 1 .143-.06h1.537a.2.2 0 0 1 .142.341l-7.555 7.63 7.612 7.689a.2.2 0 0 1-.142.34h-1.537a.2.2 0 0 1-.142-.059l-6.841-6.909-6.842 6.91a.2.2 0 0 1-.142.059H4.068a.2.2 0 0 1-.142-.341l7.612-7.689-7.555-7.63A.2.2 0 0 1 4.125 4h1.537a.2.2 0 0 1 .143.059Z'
    />
  </svg>
);

export default CloseIcon;
