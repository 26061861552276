import { fetchBulkLabelPickupByUIDs } from '@/api';
import { ROUTE } from '@/config';
import { useBulkRequestPickup, useRequestPickup } from '@/hooksApi';
import { OrderColumn, OrderStatusTab } from '@/types';
import { downloadFile } from '@/utils';
import { message } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const useSubmitOrder = (activeTab: string, selectedRowKey: string[], isAllSelected: boolean) => {
  const { t } = useTranslation();
  const navigation = useNavigate();

  const { mutate: mutateBulkRequestPickup, isLoading: LoadingBulkRequestPickup } =
    useBulkRequestPickup();

  const handleReadyToShip = () => {
    mutateBulkRequestPickup(isAllSelected ? [] : selectedRowKey, {
      onSuccess: () => {
        message.success(t('operation'));
        navigation(ROUTE.PICKUPS);
      },
    });
  };

  const handleDownloadLabels = async () => {
    const queryString = selectedRowKey
      .map((key) => `order_uid[]=${encodeURIComponent(key)}`)
      .join('&');

    // download labels
    await downloadFile(queryString, fetchBulkLabelPickupByUIDs, 'pickup-labels.pdf');
  };

  const onSubmit = useCallback(() => {
    if (activeTab === OrderStatusTab.ReadyToShip) {
      handleReadyToShip();
    } else {
      handleDownloadLabels();
    }
  }, [activeTab, handleReadyToShip, handleDownloadLabels]);

  return { onSubmit, LoadingBulkRequestPickup };
};

export default useSubmitOrder;
