import { Avatar, Flex, Space, Typography } from 'antd';

const { Text } = Typography;

interface Props {
  avatarUrl?: string;
  text: string;
  subText?: string;
}
const AvatarText = ({ subText, text, avatarUrl }: Props) => {
  return (
    <Space className='flex items-center'>
      <Avatar
        size={30}
        src={avatarUrl ?? 'https://joeschmoe.io/api/v1/random'}
        className='-mt-[2px]'
      />
      <Flex vertical className='-space-y-1'>
        <Text className=' font-normal text-sm leading-[18.2px] text-customTunaPrimary'>{text}</Text>
        <Text className=' font-normal text-[10px] leading-[18.6px] text-customGrayShade5'>
          {subText}
        </Text>
      </Flex>
    </Space>
  );
};

export default AvatarText;
