import { OrderStatusEnum } from '@/config';
import { DriverItem } from './pickup';
import { Pickup, PickupAddress } from './pickupAddress';
import { City, RegionItem } from './region';

interface Response {
  status: string;
  code: number;
  message: string;
}

export interface ResponseType<T> {
  status: string;
  code: number;
  message: string;
  object: T;
}
// Interface for a single order
interface Contact {
  full_name: string;
  address_text: string;
  phone: string;
  additional_phones?: string[];
  email?: string;
  lat?: string;
  lng?: string;
  region_uid: string;
}

export enum DecodeSize {
  S = 'Small',
  M = 'Medium',
  L = 'Large',
}

// Enum for size options
type Size = 'S' | 'M' | 'L';

// Enum for shipment fee type
export enum ShipmentFeeType {
  BY_MERCHANT = 'BY_MERCHANT',
  BY_CUSTOMER = 'BY_CUSTOMER',
}

// Enum for payment type
export enum PaymentType {
  COLLECT_ON_DELIVERY = 'COLLECT_ON_DELIVERY',
  PREPAID = 'PREPAID',
}

// Enum for pick-up type
type PickUpType = 'PICK_UP' | 'DROP_OFF';

// Interface for a single order
export interface Order {
  is_fragile?: boolean; // New field added from the JSON
  ready_to_pick_up?: boolean;
  shipment_fee_type?: ShipmentFeeType;
  payment_type?: PaymentType;
  pick_up_type?: PickUpType;
  description?: string;
  fee_customer_payable?: number;
  custom_id?: string;
  products?: ProductDetails[];
  contact_uid?: string;
  saved_pick_up_address_uid?: string;
  pick_up_address_uid?: string;
  size?: Size; // Restricted to 'S', 'M', or 'L'
  contact?: Contact; // Contact details for the order
}

//  response of orders
// export interface OrdersResponse {
//   status: string;
//   code: number;
//   message: string;
//   object: Order[];
// }
export interface OrdersResponse {
  status: string;
  code: number;
  message: string;
  object: {
    order: {
      pick_up_type: 'PICK_UP' | 'DROP_OFF'; // Define possible values
      payment_type: 'COLLECT_ON_DELIVERY' | 'PREPAID'; // Define possible values
      shipment_fee_type: 'BY_CUSTOMER' | 'BY_MERCHANT'; // Define possible values
      full_name: string;
      address_text: string;
      phone: string;
      additional_phones: string | null;
      description: string | null;
      custom_id: string | null;
      email: string | null;
      lat: number | null; // Assuming lat could be a number
      lng: number | null; // Assuming lng could be a number
      size: 'S' | 'M' | 'L'; // Define possible values for size
      is_fragile: boolean;
      fee: number;
      platform_code: string;
      uid: string;
      pick_up_barcode: string;
      updated_at: string; // ISO 8601 date format
      created_at: string; // ISO 8601 date format
      merchant: {
        uid: string;
        first_name: string;
        last_name: string;
        email: string | null;
        phone: string;
        type: 'MERCHANT'; // Assuming this is a constant value
        verified_at: string | null; // Date format, could be null
        created_at: string; // ISO 8601 date format
        updated_at: string; // ISO 8601 date format
        deleted_at: string | null; // Date format, could be null
        avatar: string | null;
      };
    };
    preview: Preview;
  };
}

export interface OrderPriceResponse {
  status: string;
  code: number;
  message: string;
  object: Preview;
}

interface PickUpAddress {
  uid: string;
  platform_code: string;
  title: string;
  type: string;
  default: number;
  full_name: string;
  phone: string;
  email: string | null;
  address_text: string;
  lat: string;
  lng: string;
  description: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}
//ORDER RESPONSE BY UID
export interface OrderByUIDResponse {
  status: string;
  code: number;
  message: string;
  object: {
    uid: string;
    platform_code: string;
    merchant_action_required: string | null;
    merchant_action_required_text: string | null;
    pick_up_barcode: string;
    is_fragile: number;
    custom_id: string | null;
    region: {
      uid: string;
      platform_code: string;
      province_code: string;
      province: string;
      zaeem_area_id: number;
      area_name: string;
      remote: number;
      created_at: string;
      updated_at: string;
      deleted_at: string | null;
    };
    fee: number;
    pick_up_type: string;
    payment_type: string;
    shipment_fee_type: string;
    size: string;
    full_name: string;
    phone: string;
    pick_up_address: PickUpAddress;

    additional_phones: string | null;
    email: string;
    address_text: string;
    lat: string;
    lng: string;
    description: string;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    preview: Preview;
    driver: string | null;
    tracking_page: string;
    tracking_auth_page: string;
    city: {
      title: string;
      slug: string;
      deleted_at: string | null;
    };
    statuses: Array<{
      title: string;
      slug: string;
      pivot: {
        issuer_type: string;
        issuer_id: number;
        created_at: string;
        updated_at: string;
      };
    }>;
    status: {
      title: string;
      slug: string;
    };
    products: Array<{
      uid: string;
      platform_code: string;
      title: string;
      price: number;
      currency: string;
      quantity: number;
      created_at?: string;
      updated_at?: string;
      deleted_at?: string | null;
      pivot?: {
        created_at: string;
        updated_at: string;
      };
    }>;
    pick_up: string | null;
  };
}

// order list status
export interface OrderListResponse {
  status: string;
  code: number;
  message: string;
  object: {
    items: OrderItem[];
    page: number;
    total: number;
    pages: number;
    perPage: number;
  };
}

export interface OrderSingleResponse {
  status: string;
  code: number;
  message: string;
  object: SingleOrderItem;
}

interface SingleOrderItem {
  uid: string;
  platform_code: string;
  rto_id: string | null;
  merchant_action_required?: number;
  merchant_action_required_text: string | null;
  merchant_action_required_merchant_text: string | null;
  pick_up_barcode: string;
  is_fragile: number;
  custom_id: string;
  region: RegionItem;
  fee: number;
  pick_up_type: string;
  payment_type: string;
  shipment_fee_type: string;
  size: string;
  full_name: string;
  phone: string;
  secondary_phone: string;
  additional_phones: string | null;
  email: string | null;
  address_text: string;
  lat: string | null;
  lng: string | null;
  description: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  preview: Preview;
  driver: DriverItem | null;
  tracking_page: string;
  tracking_auth_page: string;
  city: City;
  statuses: ShipmentStatus[];
  status: ShipmentStatus;
  products: ProductDetails[];
  pick_up: Pickup | null;
  saved_pick_up_address?: PickupAddress | null;
  fee_customer_payable: number;
}

interface ShipmentStatus {
  title: string;
  slug: string;
  merchant_title: string;
  pivot: ProductDetailsPivot;
}

interface ProductDetailsPivot {
  issuer_type?: string;
  issuer_id?: number;
  created_at: string;
  updated_at: string;
}

export interface Preview {
  orders_value: number;
  orders_shipment_fee: number;
  orders_value_with_shipment_fee: number;
  orders_value_with_platform_shipment_fee: number;
  orders_custom_shipment_fee: number;
}

export interface OrderItem {
  uid: string;
  platform_code: string;
  merchant_action_required: number;
  merchant_action_required_text: string | null;
  carrier_message: string | null;
  pick_up_barcode: string;
  is_fragile: number;
  custom_id: string;
  region: string | null;
  fee: number;
  pick_up_type: string;
  payment_type: string;
  shipment_fee_type: string;
  products_value: number;
  size: string;
  full_name: string;
  phone: string;
  additional_phones: string[];
  email: string;
  address_text: string;
  lat: string;
  lng: string;
  description: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  status: Status;
  pick_up?: Pickup | null;
  saved_pick_up_address?: PickupAddress | null;
  products: ProductDetails[];
  driver?: DriverItem | null;
}

export interface ProductPivot {
  order_id: number;
  product_id: number;
  created_at: string;
  updated_at: string;
}

export interface BasicProduct {
  title: string;
  price: number | string;
  quantity?: number | string;
  uid?: string;
}

export interface ProductDetails extends BasicProduct {
  currency?: string;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string | null;
  pivot?: ProductDetailsPivot;
  platform_code?: string;
  status?: ProductStatus;
}

export interface ProductStatus {
  title: string;
}

export interface Status {
  merchant_title: string;
  title: string;
  slug: OrderStatusEnum;
}

export interface OrdersRow {
  key: string | undefined;
  pickupAddressId: string | undefined;
  platform_code: string | undefined;
  total: number;
  status?: string;
  merchant_action_required?: number;
}

//Resolve action and response:
interface ResolvedObject {
  id: string;
  name: string;
  description: string;
  // Add other fields relevant to the object structure
}
export interface OrderResolveResponse {
  status: string;
  code: number;
  message: string;
  object: ResolvedObject[]; // Use the specific type instead of `any[]`
}
export interface OrderResolveAction {
  merchant_action_required_merchant_text: string;
}

//RTO request :
export interface OrderRtoRequest {
  merchant_action_required_merchant_text: string;
}
export enum PickupTypeEnum {
  COD = 'COD_DROP_OFF_ADDRESS',
  WAREHOUSE = 'WAREHOUSE',
}
export type OrderColumn = {
  customerAddress: string;
  customerName: string;
  key: string;
  orderDate: string;
  orderId: string;
  pickupAddressId: string;
  pickupLocation: string;
  products: ProductDetails[];
  status: string;
};

export interface OrderSingleRtoResponse {
  status: string;
  code: number;
  message: string;
  object: SingleRTO;
}

export interface OrderRtoResponse {
  status: string;
  code: number;
  message: string;
  object: {
    items: SingleRTO[];
    page: number;
    total: number;
    pages: number;
    perPage: number;
  };
}

export interface SingleRTO {
  uid: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  orders_count: number;
  shipment_fee: number;
  order_value: number | null;
  driver: DriverItem | null;
  total_price: number;
  pick_up_address: PickUpAddress;
  order: SingleOrderItem;
  order_current_status: Status;
  task: null;
}

export interface ActivityLogResponse extends Response {
  object: {
    items: ActivityItem[];
  };
}

export interface ActivityItem {
  description?: string;
  title: string;
  created_at: string;
  updated_at?: string;
  deleted_at?: string;
  images?: ImageItem[];
}

interface ImageItem {
  // it probably should be changed
  imageTitle: string;
  imageUrl: string;
}
