import { useFetchWebhooksEvents } from '@/hooksApi';
import { Flex, Form, Input, message, Modal, Select, Tag, Typography } from 'antd';
import { Store } from 'antd/es/form/interface';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const TriggerSelector = () => {
  const [selectedEvents, setSelectedEvents] = useState<string[]>([]);

  const { t } = useTranslation();

  const { data: eventList } = useFetchWebhooksEvents();

  const handleSelectedEventsChanged = (selected: string[]) => {
    setSelectedEvents(selected);
  };

  const removeSelectedEvents = (event: string) => {
    const newList = selectedEvents.filter((item) => item !== event);

    setSelectedEvents(newList);
  };

  return (
    <Flex vertical gap={16}>
      <Typography.Text className='text-base'>{t('triggers')}</Typography.Text>

      <Form.Item name='triggers' rules={[{ required: true, message: t('this_field_is_required') }]}>
        <Select
          className='custom-multi-select-item custom-select-placeholder min-h-[56px] h-fit rounded-[10px]'
          mode='multiple'
          variant='borderless'
          allowClear
          style={{ width: '100%' }}
          defaultValue={selectedEvents}
          onChange={handleSelectedEventsChanged}
          options={eventList?.object.items.map((event) => ({
            label: event.title,
            value: event.uid,
          }))}
          tagRender={(event) => (
            <Tag
              className='bg-customGrayShade3 rounded-full text-base border-none py-1 px-3'
              closable
              onClose={() => removeSelectedEvents(event.value)}
            >
              {event.label}
            </Tag>
          )}
        />
      </Form.Item>
    </Flex>
  );
};

export default TriggerSelector;
