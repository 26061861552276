import React from 'react';
import { SVGProps } from 'react';

interface SpinnerIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  fillColor?: string;
}

const SpinnerIcon = ({
  width = 24,
  height = 24,
  fillColor = '#ffffff',
  ...props
}: SpinnerIconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 40 40'
    fill='none'
    {...props}
  >
    <circle
      cx='20'
      cy='20'
      r='18'
      stroke='url(#gradient)'
      strokeWidth='4'
      fill='none'
      strokeLinecap='round'
    />
    <defs>
      <linearGradient id='gradient' x1='0' y1='0' x2='100%' y2='0'>
        <stop offset='0%' stopColor='#38383E' />
        <stop offset='85%' stopColor='white' />
      </linearGradient>
    </defs>
  </svg>
);

export default SpinnerIcon;
