import { Card } from '@/components/Card';
import { EditOutline, DeleteIcon, CopyIcon } from '@/components/Icons';
import { useDeleteWebhooks } from '@/hooksApi';
import { WebHooksDataResponse } from '@/types';
import { useQueryClient } from '@tanstack/react-query';
import { Col, Divider, Flex, message, Row, Tag, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props extends WebHooksDataResponse {
  index: number;
  setWebHookModalData: (webhookUid: string) => void;
}
const WebHookCardItem = ({ url, properties, events, index, uid, setWebHookModalData }: Props) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate: deleteWebhooks } = useDeleteWebhooks();

  const handleDeleteWebhook = () => {
    deleteWebhooks(uid, {
      onSuccess: () => {
        queryClient.invalidateQueries(['webhooks']);
        message.success(t('operation_completed_successfully'));
      },
    });
  };
  return (
    <Card extraClassName='mt-2 pt-6 pb-8 rounded-[20px]'>
      <Flex justify='space-between'>
        <Typography.Text className='text-base font-light text-customGrayShade5'>
          {'#' + (index + 1)}
        </Typography.Text>
        <Flex gap={16} className='h-full items-center ' justify='center'>
          <EditOutline
            width={24}
            height={24}
            color='#38383E'
            className='cursor-pointer'
            onClick={() => {
              setWebHookModalData(uid);
            }}
          />

          <DeleteIcon
            width={24}
            height={24}
            color='#38383E'
            className='cursor-pointer'
            onClick={handleDeleteWebhook}
          />
        </Flex>
      </Flex>
      <Divider className='my-4' />
      <Row gutter={[16, 16]}>
        <Col lg={9} md={9} xs={24}>
          <Typography.Text className='text-base font-light text-customGrayShade5'>
            {t('url')}
          </Typography.Text>
        </Col>
        <Col lg={15} md={15} xs={24}>
          <Typography.Text className='text-base font-light'>{url}</Typography.Text>
        </Col>
        <Col lg={9} md={9} xs={24}>
          <Typography.Text className='text-base font-light text-customGrayShade5'>
            {t('trigger')}
          </Typography.Text>
        </Col>
        <Col lg={15} md={15} xs={24}>
          <Typography.Text className='text-base font-light'>
            {events.map((event) => (
              <Tag
                key={event.uid}
                closable
                onClose={() => {}}
                className='bg-customGrayShade3 rounded-full text-customGrayShade5 text-base font-semibold border-none pb-1 px-2'
              >
                {event.title}
              </Tag>
            ))}
          </Typography.Text>
        </Col>
        <Col lg={9} md={9} xs={24}>
          <Typography.Text className='text-base font-light text-customGrayShade5'>
            {t('authentication_header')}
          </Typography.Text>
        </Col>
        <Col lg={15} md={15} xs={24}>
          <Typography.Text className='text-base font-light'>{properties[0]?.key}</Typography.Text>
        </Col>
        <Col lg={9} md={9} xs={24}>
          <Typography.Text className='text-base font-light text-customGrayShade5'>
            {t('authentication_key')}
          </Typography.Text>
        </Col>
        <Col lg={15} md={15} xs={24}>
          <Flex justify='start' gap={8}>
            <Typography.Text className='text-base font-light'>
              {properties[0]?.value}
            </Typography.Text>
            <CopyIcon
              width={24}
              height={24}
              color='#38383E'
              className='cursor-pointer'
              onClick={() => {}}
            />
          </Flex>
        </Col>
      </Row>
    </Card>
  );
};

export default WebHookCardItem;
