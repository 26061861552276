import { SVGProps } from 'react';

interface KeyOutlineIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const KeyOutlineIcon = ({
  width = 24,
  height = 24,
  color = '#38383E',
  ...props
}: KeyOutlineIconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill={color}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.6277 2C18.5871 2 21.7969 5.20979 21.7969 9.16927C21.7969 13.1287 18.5871 16.3385 14.6277 16.3385C12.9149 16.3385 11.3424 15.7379 10.1093 14.7358L8.01243 16.8325L10.0291 18.8492C10.1072 18.9273 10.1072 19.054 10.0291 19.1321L9.26306 19.8981C9.18496 19.9762 9.05832 19.9762 8.98022 19.8981L6.96343 17.8815L4.90528 19.9405C4.82717 20.0186 4.70054 20.0186 4.62244 19.9405L3.85643 19.1745C3.77833 19.0964 3.77833 18.9698 3.85643 18.8916L9.06111 13.6876C8.059 12.4545 7.45839 10.882 7.45839 9.16927C7.45839 5.20979 10.6682 2 14.6277 2ZM14.6277 3.4833C11.4874 3.4833 8.94169 6.02899 8.94169 9.16927C8.94169 12.3095 11.4874 14.8552 14.6277 14.8552C17.7679 14.8552 20.3136 12.3095 20.3136 9.16927C20.3136 6.02899 17.7679 3.4833 14.6277 3.4833Z'
    />
  </svg>
);

export default KeyOutlineIcon;
