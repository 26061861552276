import { useEffect, useState } from 'react';
import { Button, Card, Flex, Spin, Typography } from 'antd';
import { useOrdersProgressBar } from '@/hooks';
import CloseIcon from '../Icons/CloseIcon';
import { t } from 'i18next';
import { SpinnerIcon } from '../Icons';

export const ProgressBarToast = () => {
  const { progressToastFileName, setProgressToastFileName } = useOrdersProgressBar();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (progressToastFileName) {
      setVisible(true);
    } else {
      setTimeout(() => setVisible(false), 300);
    }
  }, [progressToastFileName]);

  return visible ? (
    <div
      className={`my-2 right-5 transition-transform duration-300 ease-in-out ${
        progressToastFileName ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-5'
      }`}
    >
      <Card className='rounded-lg shadow-card-dark' styles={{ body: { padding: '16px 16px' } }}>
        <Flex justify='space-between' className='items-center'>
          <Flex gap={8} className='items-center'>
            <div className='animate-spin w-6 h-6'>
              <SpinnerIcon />
            </div>
            {/* <Spin size='large' percent={percent} /> */}
            <Flex vertical>
              <Typography.Text className='font-light text-xs text-[10px] text-gray-400'>
                {t('export_is_in_queue')}
              </Typography.Text>
              <Typography.Text>{progressToastFileName}</Typography.Text>
            </Flex>
          </Flex>
          <Flex
            justify='center'
            className='w-[24px] h-[24px] cursor-pointer items-center'
            onClick={() => setProgressToastFileName(null)}
          >
            <CloseIcon color='#9E9E9E' width={16} height={16} />
          </Flex>
        </Flex>
      </Card>
    </div>
  ) : null;
};
