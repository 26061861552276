import React, { useEffect, useState } from 'react';
import { Col, Flex, Form, FormInstance, Radio, RadioChangeEvent, Row, Typography } from 'antd';
import { Card } from '../Card';
import { Heading } from '../Heading';
import { useTranslation } from 'react-i18next';
import { CustomRadioButton } from '../CustomRadioButton';
import EditOrderShipment from './EditOrderShipment';
import { PaymentType } from '@/types';

const { Text } = Typography;

interface PaymentCardProps {
  form: FormInstance; // Add form instance prop
  title: string;
  orderTitle: string;
  codTitle: string;
  prepaidTitle: string;
  shipmentFeeTitle: string;
  customerTitle: string;
  merchantTitle: string;
  walletBalanceText: string;
  availableText: string;
  iqdText: string;
  discountPlaceholder: string;
  CodOrPrepaid?: string; // Add CodOrPrepaid as a typed prop
  CustomerOrMerchant?: string; // Add CustomerOrMerchant as a typed prop
}

const EditPaymentCard: React.FC<PaymentCardProps> = ({
  form,
  title,
  orderTitle,
  codTitle,
  prepaidTitle,
  shipmentFeeTitle,
  customerTitle,
  merchantTitle,
  walletBalanceText,
  availableText,
  iqdText,
  discountPlaceholder,
  CodOrPrepaid,
  CustomerOrMerchant,
}) => {
  const { t } = useTranslation();
  const [selectedCodOrPrepaidValue, setSelectedCodOrPrepaidValue] = useState(''); // State to track the selected radio
  const [selectedCustomerOrMerchantValue, setSelectedCustomerOrMerchantValue] = useState(''); // State to track the selected radio

  useEffect(() => {
    if (CodOrPrepaid && CustomerOrMerchant) {
      form.setFieldsValue({
        CodOrPrepaid: CodOrPrepaid,
        CustomerOrMerchant: CustomerOrMerchant,
      });
      setSelectedCodOrPrepaidValue(CodOrPrepaid || '');
      setSelectedCustomerOrMerchantValue(CustomerOrMerchant || '');
    }
  }, [CodOrPrepaid, CustomerOrMerchant]);

  const handleRadioChangeCodOrPrepaid = (e: RadioChangeEvent) => {
    setSelectedCodOrPrepaidValue(e.target.value);
  };

  const handleRadioChangeCustomerOrMerchant = (e: RadioChangeEvent) => {
    setSelectedCustomerOrMerchantValue(e.target.value);
  };
  return (
    <Card>
      <Heading title={title} fontSize='text-lg' fontWeight='font-bold' extraClassName='uppercase' />
      <Flex vertical className='mt-4'>
        <Text className='font-normal mb-2 text-xs leading-[10px] text-customGrayShade5 capitalize'>
          {orderTitle}
        </Text>

        <Form.Item name='CodOrPrepaid' initialValue={CodOrPrepaid}>
          <Radio.Group
            value={selectedCodOrPrepaidValue}
            onChange={handleRadioChangeCodOrPrepaid}
            className='w-full'
          >
            <Row gutter={[24, 16]} className='mt-[19.5px]'>
              <Col lg={12} md={24} xs={24}>
                <CustomRadioButton
                  value={PaymentType.COLLECT_ON_DELIVERY}
                  selectedValue={selectedCodOrPrepaidValue}
                  label={codTitle}
                />
              </Col>
              <Col lg={12} md={24} xs={24}>
                <CustomRadioButton
                  value={PaymentType.COLLECT_ON_DELIVERY}
                  selectedValue={selectedCodOrPrepaidValue}
                  label={prepaidTitle}
                />
              </Col>
            </Row>
          </Radio.Group>
        </Form.Item>
      </Flex>
      <Flex vertical className='mt-4'>
        <Text className='font-normal mb-2 text-xs leading-[10px] text-customGrayShade5 capitalize'>
          {shipmentFeeTitle}
        </Text>
        <Form.Item name='CustomerOrMerchant' initialValue={CustomerOrMerchant}>
          <Radio.Group
            value={selectedCustomerOrMerchantValue}
            onChange={handleRadioChangeCustomerOrMerchant}
            className='w-full'
          >
            <Row gutter={[24, 16]} className='mt-[19.5px]'>
              <Col lg={12} md={24} xs={24}>
                <CustomRadioButton
                  value='BY_CUSTOMER'
                  selectedValue={selectedCustomerOrMerchantValue}
                  label={customerTitle}
                />
              </Col>
              <Col lg={12} md={24} xs={24}>
                <CustomRadioButton
                  value='BY_MERCHANT'
                  selectedValue={selectedCustomerOrMerchantValue}
                  label={merchantTitle}
                />
              </Col>
            </Row>
          </Radio.Group>
        </Form.Item>
      </Flex>

      <EditOrderShipment
        orderValueText={t('order_value')}
        shipmentFeeText={t('shipment_fee')}
        totalText={t('total')}
        currency={t('iqd')}
      />
    </Card>
  );
};

export default EditPaymentCard;
