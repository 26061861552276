import {
  AccessTokenSection,
  Card,
  CompanyIcon,
  CustomSettingsCard,
  EnvironmentIcon,
  Heading,
  HelpAndSupport,
  IntegrationCard,
  LogOutIcon,
  PersonalCompanyInfo,
  PickupDrop,
  WebHookSection,
} from '@/components';
import { ROUTE, THEME_VARIABLE } from '@/config';
import { useUserStore } from '@/stores';
import { useAuthStore } from '@/stores/authStore';
import type { MenuProps } from 'antd';
import { Col, ConfigProvider, Flex, Menu, Row } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export enum SettingSection {
  Profile = 'profile',
  Location = 'location',
  TeamMembers = 'team_members',
  AccessToken = 'access_token',
  WebHook = 'web_hook',
}

const Settings = () => {
  const [selectedSection, setSelectedSection] = useState<SettingSection>(SettingSection.WebHook);
  const clearToken = useAuthStore((state) => state.clearToken);
  const setUser = useUserStore((state) => state.setUser);
  const navigation = useNavigate();
  const { t } = useTranslation();

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    setSelectedSection(e.key as SettingSection);
  };
  const renderContent = () => {
    switch (selectedSection) {
      case SettingSection.Profile:
        return <PersonalCompanyInfo />;
      case SettingSection.Location:
        return <PickupDrop />;
      case SettingSection.TeamMembers:
        return <Flex>TeamMembers</Flex>;
      case SettingSection.AccessToken:
        return <AccessTokenSection />;
      case SettingSection.WebHook:
        return <WebHookSection />;
      default:
        return null;
    }
  };

  const handleLogOut = () => {
    clearToken?.();
    setUser(undefined);
    navigation(ROUTE.LOGIN);
  };

  return (
    <Row gutter={[24, 16]} className='mt-[34px]'>
      <Col lg={10} md={24} xs={24}>
        {/* V0 remove */}
        {/* <FreeOrders referText={t('refer_and_get_rewarded')} mgCode='MG-567' /> */}
        <ConfigProvider
          theme={{
            token: {
              colorPrimaryBg: THEME_VARIABLE.customYellowPrimary,
            },
          }}
        >
          <Flex vertical>
            <Heading
              title={t('account')}
              upperCase
              fontSize='text-lg'
              fontWeight='font-bold'
              extraClassName='leading-[20.88px]'
            />
            <Menu
              className='py-4 px-5 rounded-lg shadow-card-dark mt-2'
              mode='vertical'
              selectedKeys={[selectedSection]}
              onClick={handleMenuClick}
            >
              <Menu.Item key={SettingSection.Profile} className='h-[64px] '>
                <CustomSettingsCard
                  IconLeft={
                    <CompanyIcon width={24} height={24} color={THEME_VARIABLE.customTunaPrimary} />
                  }
                  title={t('profile')}
                  description={t('manage_your_personal')}
                  extraClassName='h-[64px] rounded-4'
                  borderBottomStyle={`1px solid ${THEME_VARIABLE.customGrayShade3}`}
                  leftIconBackgroundColor={
                    selectedSection === SettingSection.Profile
                      ? 'bg-customYellowPrimary'
                      : 'bg-customGrayShade2'
                  }
                  descriptionColor={
                    selectedSection === SettingSection.Profile
                      ? 'text-customTunaPrimary'
                      : 'text-customGrayShade5'
                  }
                />
              </Menu.Item>
              <Menu.Item key={SettingSection.Location} className='h-[64px]'>
                <CustomSettingsCard
                  IconLeft={
                    <EnvironmentIcon
                      width={24}
                      height={24}
                      color={THEME_VARIABLE.customTunaPrimary}
                    />
                  }
                  title={t('locations')}
                  description={t('manage_your_addresses')}
                  extraClassName='h-[64px] '
                  //borderBottomStyle={`1px solid ${THEME_VARIABLE.customGrayShade3}`}
                  leftIconBackgroundColor={
                    selectedSection === SettingSection.Location
                      ? 'bg-customYellowPrimary'
                      : 'bg-customGrayShade2'
                  }
                  descriptionColor={
                    selectedSection === SettingSection.Location
                      ? 'text-customTunaPrimary'
                      : 'text-customGrayShade5'
                  }
                />
              </Menu.Item>
              {/* V0 remove */}
              {/* <Menu.Item key='teamMembers' className='h-[64px]'>
              <CustomSettingsCard
                IconLeft={
                  <TeamIcon width={24} height={24} color={THEME_VARIABLE.customTunaPrimary} />
                }
                title={t('team_members')}
                description={t('manage_your_team_member')}
                extraClassName='h-[64px] '
                leftIconBackgroundColor={
                  selectedSection === 'teamMembers' ? 'bg-customYellowPrimary' : 'bg-customGrayShade2'
                }
                descriptionColor={
                  selectedSection === 'teamMembers' ? 'text-customTunaPrimary' : 'text-customGrayShade5'
                }
              />
            </Menu.Item> */}
            </Menu>
          </Flex>
          {/* V0 remove */}
          {/* <ShippingCard /> */}
          <IntegrationCard selectedSection={selectedSection} handleMenuClick={handleMenuClick} />
        </ConfigProvider>

        <HelpAndSupport />
        <Card extraClassName='mt-6 px-12'>
          <Flex onClick={handleLogOut} className='cursor-pointer'>
            <CustomSettingsCard
              IconLeft={<LogOutIcon width={24} height={24} color={THEME_VARIABLE.customRed} />}
              title={t('log_out')}
              leftIconBackgroundColor='bg-customRedShade1'
            />
          </Flex>
        </Card>
      </Col>
      <Col lg={14} md={24} xs={24}>
        {renderContent()}
      </Col>
    </Row>
  );
};

export default Settings;
