import {
  createApiToken,
  createWebhookProperty,
  createWebhooks,
  deleteApiToken,
  deleteWebhookProperty,
  deleteWebhooks,
  fetchApiTokens,
  fetchWebhookByUid,
  fetchWebhooks,
  fetchWebhooksEvents,
  fetchWebhooksProperties,
  updateWebhookProperty,
  updateWebhooks,
} from '@/api';
import { useSentryQuery, useSentryMutation } from '../utils/sentryQueryWrapper';
import {
  AccessTokenDataListResponse,
  AccessTokenResponse,
  ErrorMessageResponse,
  ResponseType,
  WebHooksCreateRequest,
  WebHooksDataListResponse,
  WebHooksDataResponse,
  WebHooksEventDataListResponse,
  WebHooksProperty,
  WebHooksPropertyCreateRequest,
  WebHooksPropertyListResponse,
} from '@/types';

export const useFetchApiTokens = () => {
  return useSentryQuery<ResponseType<AccessTokenDataListResponse>, Error>(
    ['api-token'],
    () => fetchApiTokens(),
    {},
  );
};

export const useCreateApiToken = () => {
  return useSentryMutation<ResponseType<AccessTokenResponse>, ErrorMessageResponse, string>(
    (name: string) => createApiToken(name),
  );
};

export const useDeleteApiToken = () => {
  return useSentryMutation<void, ErrorMessageResponse, string>((apiKey: string) =>
    deleteApiToken(apiKey),
  );
};

export const useFetchWebhooks = () => {
  return useSentryQuery<ResponseType<WebHooksDataListResponse>, Error>(
    ['webhooks'],
    () => fetchWebhooks(),
    {},
  );
};
export const useFetchWebhooksByUid = (uid?: string) => {
  return useSentryQuery<ResponseType<WebHooksDataResponse>, Error>(
    ['webhook-by-uid', uid],
    () => fetchWebhookByUid(uid ?? ''),
    {
      enabled: !!uid,
    },
  );
};

export const useDeleteWebhooks = () => {
  return useSentryMutation<void, ErrorMessageResponse, string>((uid: string) =>
    deleteWebhooks(uid),
  );
};

export const useCreateWebhooks = () => {
  return useSentryMutation<
    ResponseType<WebHooksDataResponse>,
    ErrorMessageResponse,
    WebHooksCreateRequest
  >((data: WebHooksCreateRequest) => createWebhooks(data));
};

export const useUpdateWebhooks = () => {
  return useSentryMutation<void, ErrorMessageResponse, WebHooksCreateRequest>(
    (data: WebHooksCreateRequest) => updateWebhooks(data.uid ?? '', { ...data }),
  );
};

export const useFetchWebhooksEvents = () => {
  return useSentryQuery<ResponseType<WebHooksEventDataListResponse>, Error>(
    ['webhooks-events'],
    () => fetchWebhooksEvents(),
    {},
  );
};

export const useFetchWebhooksProperties = (webhookUid?: string) => {
  return useSentryQuery<ResponseType<WebHooksPropertyListResponse>, Error>(
    ['webhooks-properties'],
    () => fetchWebhooksProperties(webhookUid ?? ''),
    {
      enabled: !!webhookUid,
    },
  );
};

export const useCreateWebhookProperty = () => {
  return useSentryMutation<
    ResponseType<WebHooksProperty>,
    ErrorMessageResponse,
    WebHooksPropertyCreateRequest
  >((data: WebHooksPropertyCreateRequest) => createWebhookProperty(data));
};

export const useDeleteWebhookProperty = () => {
  return useSentryMutation<void, ErrorMessageResponse, { webhookUid: string; uid: string }>(
    (data: { webhookUid: string; uid: string }) => deleteWebhookProperty(data.webhookUid, data.uid),
  );
};

export const useUpdateWebhookProperty = () => {
  return useSentryMutation<
    void,
    ErrorMessageResponse,
    { webhookUid: string; propertyData: WebHooksProperty }
  >((data: { webhookUid: string; propertyData: WebHooksProperty }) =>
    updateWebhookProperty(data.webhookUid, data.propertyData),
  );
};
