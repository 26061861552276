import React from 'react';
import { Card } from '../Card';
import { Heading } from '../Heading';
import { ConfigProvider, Flex, Table, Tag, Typography } from 'antd';
import { OrderStatusEnum, THEME_VARIABLE } from '@/config';
import { ProductDetails } from '@/types';
import { ColumnsType } from 'antd/es/table';
import { StatusTag } from '../Status';
import { t } from 'i18next';
import { getStatusTagData } from '@/utils/getStatus';

interface DataType {
  key: string;
  productName: string;
  status: string;
  price: string;
}

// Define the props interface
interface ProductDetailCardProps {
  productName: string;
  price: string;
  title: string;
  status: string;
  products: ProductDetails[];
  currency: string;
}

const ProductDetailCard: React.FC<ProductDetailCardProps> = ({
  productName,
  price,
  products,
  title,
  status,
  currency,
}) => {
  const columns: ColumnsType<DataType> = [
    {
      title: <span style={{ fontWeight: 'bold' }}>{productName}</span>,
      dataIndex: 'productName',
      key: 'productName',
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>{status}</span>,
      dataIndex: 'status',
      key: 'status',
      render: (status) =>
        status ? (
          <StatusTag
            text={t('rejected')}
            color={getStatusTagData(OrderStatusEnum.on_hold)?.tagColor}
            iconColor={getStatusTagData(OrderStatusEnum.on_hold)?.iconColor}
          />
        ) : null,
    },
    {
      title: <span style={{ fontWeight: 'bold' }}>{price}</span>,
      dataIndex: 'price',
      key: 'price',
      align: 'right',
    },
  ];

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBg: THEME_VARIABLE.colorWhite,
            headerSplitColor: 'transparent',
          },
        },
      }}
    >
      <Card>
        <Heading
          title={title}
          fontSize='text-lg'
          fontWeight='font-bold'
          extraClassName='uppercase'
        />

        <div className='product-table-wrapper'>
          <Table
            columns={columns}
            dataSource={products.map((item) => ({
              key: item.uid ?? '',
              productName: item.title ?? '',
              status: item.status?.title === 'rejected' ? item.status?.title : '',
              price: item.price + ' ' + currency,
            }))}
            pagination={false}
            bordered={false}
          />
        </div>
      </Card>
    </ConfigProvider>
  );
};

export default ProductDetailCard;
