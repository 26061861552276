import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/en';
import 'dayjs/locale/ar-iq';

dayjs.extend(localeData);
dayjs.extend(updateLocale);

//default language
dayjs.locale('ar-iq');

dayjs.updateLocale('ar-iq', {
  months: [
    'كانون الثاني',
    'شباط',
    'آذار',
    'نيسان',
    'أيار',
    'حزيران',
    'تموز',
    'آب',
    'أيلول',
    'تشرين الأول',
    'تشرين الثاني',
    'كانون الأول',
  ],
  weekdays: ['الأحد', 'الإثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت'],
});

export const setDayjsLanguage = (language: 'en' | 'ar') => {
  dayjs.locale(language === 'ar' ? 'ar-iq' : 'en');
};

export default dayjs;
