import { create } from 'zustand';

interface ProgressBar {
  progressToastFileName: string | null;
  setProgressToastFileName: (value: string | null) => void;
}

const useProgressBar = create<ProgressBar>((set) => ({
  progressToastFileName: null,
  setProgressToastFileName: (value) => set({ progressToastFileName: value }),
}));

export default useProgressBar;
