import '@customerio/cdp-analytics-browser';
import { useCallback } from 'react';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

const useCustomerIO = () => {
  const { t } = useTranslation();

  const trackEvent = useCallback(
    (eventName: string, eventData: Record<string, any>, successMessage?: string) => {
      if (typeof window.analytics !== 'undefined') {
        window.analytics
          .track(eventName, eventData)
          .then(() => {
            if (successMessage)
              notification.success({
                message: t('success'),
                description: successMessage,
              });
          })
          .catch((error) => {
            console.error('ERROR: ', error);
          });
      } else {
        console.warn('Analytics is not loaded. Event tracking skipped:', eventName);
      }
    },
    [],
  );

  const identifyUser = useCallback((userId: string, traits: Record<string, any>) => {
    if (typeof window.analytics !== 'undefined') {
      window.analytics.identify(userId, traits);
    } else {
      console.warn('Analytics is not loaded. User identification skipped:', userId);
    }
  }, []);

  return { trackEvent, identifyUser };
};

export default useCustomerIO;
