import { ALLOW_FILE_CSV } from '@/config';
import { message, Upload } from 'antd';
import { RcFile } from 'antd/es/upload';
import { t } from 'i18next';

export const checkFileValidation = (file: RcFile) => {
  const isCSVOrXLSX = ALLOW_FILE_CSV.includes(file.type);
  const isFileSizeValid = file.size <= 1048576;

  if (!isCSVOrXLSX) {
    message.error(t('only_csv_xlsx'));
    return Upload.LIST_IGNORE;
  }

  if (!isFileSizeValid) {
    message.error(t('file_size_limit_exceeded'));
    return Upload.LIST_IGNORE;
  }

  return false;
};
