import {
  DownloadIcon,
  EyeIcon,
  Heading,
  PickupsIcon,
  PriceWithUnit,
  ResolveIcon,
  StatusTag,
} from '@/components';
import {
  CUSTOM_ID,
  CUSTOMER_NAME,
  LM_COMMENT,
  ON_HOLD_REASON,
  ORDER_ACTIONS,
  ORDER_DATE,
  ORDER_ID,
  ORDER_STATUS,
  PICKUP_LOCATION,
  PRODUCTS,
  ROUTE,
  THEME_VARIABLE,
  OrderStatusEnum,
} from '@/config';
import { ColumnsType } from 'antd/es/table';
import { OrdersRow, OrderStatusTab, Product } from '@/types';
import { ActionsTable } from '@/components/Action';
import { Flex, message } from 'antd';
import { downloadFile } from '@/utils';
import { getOrderTabByStatus, getStatusTagData } from '@/utils/getStatus';
import { useRequestPickup } from '@/hooksApi';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { fetchSingleLabelPickupByUID } from '@/api';
import { useState } from 'react';
import { OrderDataType } from '@/hooks/useOrderData';
import { t } from 'i18next';
import { ActionProps, OrderMenuItemsEnum } from '@/components/Action/ActionsTable';

const renderHeading = (text: string | undefined) => <Heading title={text} fontSize='text-sm' />;

const renderPriceWithUnit = (
  value: number | undefined,
  t: { (key: string): string; (arg0: string): string | undefined },
) => (
  <Flex align='center' gap={2}>
    <Heading title={t('total')} fontSize='text-sm' />
    <PriceWithUnit
      color={THEME_VARIABLE.customTunaPrimary}
      value={value}
      subTitle={t('iqd')}
      fontSize='14px'
      fontSizeSub='text-sm'
      fontWeight='font-normal'
      fontWeightSub='font-medium'
      tagStyle='bg-customGrayShade3 w-fit px-2 rounded-full border-[1px] border-solid border-customGrayShade4'
    />
  </Flex>
);

const getStatusTitle = (slug: string) => t(`merchant_order_status.${slug}`);

export const getColumns = (
  hasSearchValue: boolean,
  activeTab: OrderStatusTab,
  activeSubTab: string,
  t: (key: string) => string,
): ColumnsType<any> => {
  const columns: any[] = [
    {
      title: t('order_date'),

      dataIndex: ORDER_DATE,
      key: ORDER_DATE,
      sorter: (a: { orderDate: Date }, b: { orderDate: Date }) =>
        new Date(a.orderDate).getTime() - new Date(b.orderDate).getTime(),
      render: (text: string) => renderHeading(text),
    },
    {
      title: t('order_id'),
      dataIndex: ORDER_ID,
      key: ORDER_ID,
      render: renderHeading,
    },
    {
      title: t('customer_detail'),
      dataIndex: CUSTOMER_NAME,
      key: CUSTOMER_NAME,
      render: (
        _: any,
        record: { customerName: string | undefined; customerAddress: string | undefined },
      ) => (
        <>
          <Heading title={record.customerName} fontSize='text-base' />
          <Heading
            title={record.customerAddress}
            fontSize='text-sm'
            color='text-customGrayShade5'
          />
        </>
      ),
    },
    {
      title: t('pickup_location'),
      dataIndex: PICKUP_LOCATION,
      key: PICKUP_LOCATION,
      render: (text: string) => renderHeading(text),
    },
    {
      title: t('product'),
      dataIndex: PRODUCTS,
      key: PRODUCTS,
      render: (products: Product[], record: { orderValue: number | undefined }) => (
        <Flex vertical>
          {products?.map((product: Product) => (
            <Flex vertical key={product.uid}>
              <Heading title={`${product.name} X ${product.quantity}`} fontSize='text-sm' />
            </Flex>
          ))}
          {renderPriceWithUnit(record?.orderValue, t)}
        </Flex>
      ),
    },
    {
      title: t('status'),
      dataIndex: ORDER_STATUS,
      key: ORDER_STATUS,
      render: (text: OrderStatusEnum) => (
        <StatusTag
          text={getStatusTitle(text)}
          color={getStatusTagData(text)?.tagColor}
          iconColor={getStatusTagData(text)?.iconColor}
        />
      ),
    },
    {
      title: t('filter_custom_id'),
      dataIndex: CUSTOM_ID,
      key: CUSTOM_ID,
      render: (text: string) => renderHeading(text),
    },
  ];

  if (
    (activeTab === OrderStatusTab.InProgress && ['all', 'postponed'].includes(activeSubTab)) ||
    activeTab === OrderStatusTab.OnHold
  ) {
    columns.push(
      {
        title: t('reason'),
        dataIndex: ON_HOLD_REASON,
        key: ON_HOLD_REASON,
        render: (text: string) => renderHeading(text),
      },
      {
        title: t('LM_comment'),
        dataIndex: LM_COMMENT,
        key: LM_COMMENT,
        render: (text: string) => renderHeading(text),
      },
    );
  }

  columns.push(getActionColumn(hasSearchValue ? undefined : activeTab));

  return columns || [];
};

const renderActionColumnByStatus = (
  orderTab: OrderStatusTab,
  data: OrderDataType,
  handleShipment: (id: string) => void,
  selectedId: string | null,
  setSelectedId: (id: string | null) => void,
) => {
  const disableOnHold = (status?: string) => {
    return ['hold-resolved', 'on-hold'].includes(status || '');
  };

  const handleDownloadLabel = async () => {
    setSelectedId(data.key);
    await downloadFile(
      data.key as string,
      fetchSingleLabelPickupByUID,
      `${data?.platform_code}.pdf`,
    );
    setSelectedId(null);
  };

  const inProgressActionsByStatus: Partial<Record<OrderStatusEnum, OrderMenuItemsEnum[]>> = {
    // actions for different status of in-progress tab
    [OrderStatusEnum.scheduled]: [
      OrderMenuItemsEnum.EDIT_ORDER,
      OrderMenuItemsEnum.VIEW_ORDER,
      OrderMenuItemsEnum.CANCEL_ORDER,
    ],
    [OrderStatusEnum.out_for_collecting]: [
      OrderMenuItemsEnum.EDIT_ORDER,
      OrderMenuItemsEnum.VIEW_ORDER,
      OrderMenuItemsEnum.CANCEL_ORDER,
    ],
    [OrderStatusEnum.collecting]: [OrderMenuItemsEnum.VIEW_ORDER, OrderMenuItemsEnum.CANCEL_ORDER],

    [OrderStatusEnum.collected]: [OrderMenuItemsEnum.VIEW_ORDER, OrderMenuItemsEnum.CANCEL_ORDER],
    [OrderStatusEnum.sorted]: [OrderMenuItemsEnum.VIEW_ORDER, OrderMenuItemsEnum.CANCEL_ORDER],
    [OrderStatusEnum.received_warehouse]: [
      OrderMenuItemsEnum.VIEW_ORDER,
      OrderMenuItemsEnum.CANCEL_ORDER,
    ],
    [OrderStatusEnum.in_transit]: [
      OrderMenuItemsEnum.VIEW_ORDER,
      OrderMenuItemsEnum.DISABLE_CANCEL_ORDER,
    ],
  };

  const actionTableProps = {
    uid: data.key,
    activeTab: orderTab,
    orderData: data,
    savePrevPage: true,
  };

  const tableActionsByTab: { [key in OrderStatusTab]: ActionProps } = {
    [OrderStatusTab.ReadyToShip]: {
      button: {
        label: t('ship_now'),
        onClick: () => handleShipment(data.key),
        icon: <PickupsIcon />,
        disabled: !data.pickupAddressId,
      },
      cancelLabel: t('delete'),
      menuItems: [
        OrderMenuItemsEnum.EDIT_ORDER,
        OrderMenuItemsEnum.VIEW_ORDER,
        OrderMenuItemsEnum.CANCEL_ORDER,
      ],
    },
    [OrderStatusTab.InProgress]: {
      menuItems: inProgressActionsByStatus[data.status as OrderStatusEnum] ?? [
        OrderMenuItemsEnum.VIEW_ORDER,
      ],
      button: {
        label: t('label'),
        onClick: handleDownloadLabel,
        icon: <DownloadIcon />,
        disabled: selectedId === data.key,
      },
    },
    [OrderStatusTab.OnHold]: {
      menuItems: disableOnHold(data?.status)
        ? [OrderMenuItemsEnum.VIEW_ORDER]
        : [
            OrderMenuItemsEnum.EDIT_ORDER,
            OrderMenuItemsEnum.VIEW_ORDER,
            OrderMenuItemsEnum.CANCEL_ORDER,
          ],
      button: data.merchant_action_required
        ? {
            label: t('resolve'),
            onClick: 'openResolveModal',
            icon: <ResolveIcon />,
          }
        : undefined,
    },
    [OrderStatusTab.RTO]: {
      button: {
        navigateLink: `${ROUTE.ORDER_DETAILS}/${data?.key}`,
        icon: <EyeIcon />,
      },
    },
    [OrderStatusTab.Finished]: {
      button: {
        navigateLink: `${ROUTE.ORDER_DETAILS}/${data?.key}`,
        icon: <EyeIcon />,
      },
    },
  };

  return <ActionsTable {...actionTableProps} {...tableActionsByTab[orderTab]} />;
};

const getActionColumn = (selectedTab?: OrderStatusTab) => {
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const { mutate: mutateRequestPickup } = useRequestPickup();

  const queryClient = useQueryClient();
  const navigation = useNavigate();

  const handleShipment = (id: string) => {
    mutateRequestPickup(
      {
        order_uids: [id],
      },
      {
        onSuccess: () => {
          message.success(t('operation'));
          queryClient.refetchQueries(['orders-readyToShip']);
          navigation(ROUTE.PICKUPS);
        },
      },
    );
  };

  return {
    title: t('actions'),
    key: ORDER_ACTIONS,
    render: (data: OrderDataType) => {
      const orderTab =
        selectedTab ??
        (getOrderTabByStatus(data.status as OrderStatusEnum) || OrderStatusTab.ReadyToShip);

      return renderActionColumnByStatus(orderTab, data, handleShipment, selectedId, setSelectedId);
    },
    width: '5%',
    fixed: 'right' as 'right' | undefined,
  };
};
