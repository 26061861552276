import { DeleteIcon, DownloadIcon, EditOutline, EyeIcon, Heading, StatusTag } from '@/components';
import {
  DRIVER,
  LOCATION,
  ORDER_ACTIONS,
  ORDER_STATUS,
  PICKUP,
  ROUTE,
  IMAGE_SAMPLE,
  OrderStatusEnum,
} from '@/config';
import { ColumnsType } from 'antd/es/table';
import { MenuProps, message, Typography } from 'antd';
import { ITable } from '@/components/TableAction/TableAction';
import { ActionsTable } from '@/components/Action';
import UserContactCard from '@/components/Card/UserContactCard';
import { StatusEnum } from '@/types';
import { useNavigate } from 'react-router-dom';
import { getStatusTagData } from '@/utils/getStatus';
import { useCancelPickupById } from '@/hooksApi';
import { useQueryClient } from '@tanstack/react-query';
import { downloadFile } from '@/utils';
import { fetchLabelsPickupByUID } from '@/api';
import { useState } from 'react';

export interface OrderData extends ITable {
  pickupTime: string;
  pickupLocation: string;
  location: string;
  driver: {
    phone: string;
  };
  status: StatusEnum;
  driver_tasks_Icon: string;
}

const { Text } = Typography;

export const getColumns = (t: (key: string) => string) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate: CancelPickupById, isLoading: cancelPickupLoading } = useCancelPickupById();
  const [idSelected, setIdSelected] = useState<string | null>();

  const getMenuItems = (uid: string, status: string) => {
    // Conditionally return items based on the status
    if (status === 'collected') {
      // Return only the "view" option if the status is "collected"
      return [
        {
          key: '1',
          label: t('view'),
          onClick: () => navigate(`${ROUTE.PICKUPS_DETAILS}/${uid}`),
          leadingIcon: <EyeIcon stroke='#fff' fill='#fff' />,
        },
      ];
    }

    return [
      {
        key: '1',
        label: t('view'),
        onClick: () => navigate(`${ROUTE.PICKUPS_DETAILS}/${uid}`),
        leadingIcon: <EyeIcon stroke='#fff' fill='#fff' />,
      },
      {
        key: '3',
        label: t('cancel'),
        leadingIcon: <DeleteIcon />,
        loading: cancelPickupLoading,
        disabled: !['new', 'scheduled', 'out-for-collecting', 'out-for-collection'].includes(
          status || '',
        ),
        onClick: () => {
          CancelPickupById(uid, {
            onSuccess: () => {
              message.success(t('operation'));
              queryClient.refetchQueries(['pickups']);
            },
          });
        },
      },
    ];
  };

  const columns: ColumnsType<OrderData> = [
    {
      title: <Text className='font-bold text-xs leading-[15.23px]'>{t('pickup_time')}</Text>,
      dataIndex: PICKUP,
      key: PICKUP,
      render: (_, record) => (
        <>
          <Heading title={record.pickupTime} extraClassName='mb-2' />
          <Heading title={record.pickupLocation} />
        </>
      ),
    },
    {
      title: <Text className='font-bold text-xs leading-[15.23px]'>{t('location')}</Text>,
      dataIndex: LOCATION,
      key: LOCATION,
      render: (text) => <Heading title={text} fontSize='text-sm' />,
    },
    {
      title: <Text className='font-bold text-xs leading-[15.23px]'>{t('driver')}</Text>,
      dataIndex: DRIVER,
      key: DRIVER,
      render: (record) => {
        return (
          <UserContactCard
            profileImageUrl={IMAGE_SAMPLE}
            rating={4.5}
            assign={record?.assign}
            textItem={<Heading title={record.name || '----'} fontSize='text-sm' />}
            subTitleItem={<Heading title={record.phone || '----'} fontSize='text-sm' />}
          />
        );
      },
    },
    {
      title: <Text className='font-bold text-xs leading-[15.23px]'>{t('status')}</Text>,
      dataIndex: ORDER_STATUS,
      key: ORDER_STATUS,
      render: (text: OrderStatusEnum) => {
        const status = getStatusTagData(text);
        return (
          <StatusTag
            text={t(text)}
            color={status?.tagColor || 'processing'}
            className='rounded-full text-sm'
            iconColor={status?.iconColor || ''}
          />
        );
      },
    },
    {
      title: <Text className='font-bold text-xs leading-[15.23px]'>{t('actions')}</Text>,
      key: ORDER_ACTIONS,
      render: (record: { platform_code: string; key: string | undefined; status: string }) => {
        return (
          <ActionsTable
            button={{
              label: t('labels'),
              onClick: async () => {
                setIdSelected(record.key);
                await downloadFile(
                  record.key as string,
                  fetchLabelsPickupByUID,
                  `${record?.platform_code}.pdf`,
                );
                setIdSelected(null);
              },
              icon: <DownloadIcon />,
              disabled: idSelected === record.key,
            }}
            customMenuItems={getMenuItems(record.key || '', record.status || 'new')}
          />
        );
      },
      width: '10%',
      fixed: 'right' as 'right' | undefined,
    },
  ];

  return columns;
};
