export const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', function () {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then(function (registration) {
          console.log('Service Worker registered with scope:', registration.scope);
          askNotificationPermission();
        })
        .catch(function (error) {
          console.log('Service Worker registration failed:', error);
        });
    });
  }
};

export const askNotificationPermission = () => {
  if ('Notification' in window) {
    Notification.requestPermission().then(function (permission) {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
      } else {
        console.log('Notification permission denied.');
      }
    });
  }
};

export const subscribeUserToPush = () => {
  navigator.serviceWorker.ready.then(function (registration) {
    const options = { userVisibleOnly: true, applicationServerKey: 'YOUR_PUBLIC_VAPID_KEY' };
    return registration.pushManager
      .subscribe(options)
      .then(function (subscription) {
        console.log('User is subscribed:', subscription);
        //TODO
        fetch('https://your-api-endpoint/customerio-subscription', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(subscription),
        });
      })
      .catch(function (err) {
        console.error('Failed to subscribe the user:', err);
      });
  });
};
