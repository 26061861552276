import { useState } from 'react';
import { RcFile } from 'antd/es/upload/interface';
import { checkFileValidation } from '@/utils';
import { UseMutateFunction } from '@tanstack/react-query';

export type mutateFunctionType = UseMutateFunction<any, any, FormData, unknown>;

export const useFileUpload = (onSuccess: (data: any) => void) => {
  const [uploadedFile, setUploadedFile] = useState<RcFile | null>(null);

  const handleBeforeUpload = (file: RcFile) => {
    const validationError = checkFileValidation(file);
    if (!validationError) setUploadedFile(file);
    return validationError;
  };
  const uploadFile = async (mutateFunction: mutateFunctionType, _file?: RcFile) => {
    const file = uploadedFile ?? _file;
    if (file) {
      const formData = new FormData();
      formData.append('excel', file);

      mutateFunction(formData, {
        onSuccess: (data: any) => {
          onSuccess(data);
        },
      });
    }
  };

  const resetFile = () => {
    setUploadedFile(null);
  };

  return { uploadedFile, handleBeforeUpload, uploadFile, resetFile };
};
