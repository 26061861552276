import { useEffect } from 'react';
import { Col, Flex, Radio, RadioChangeEvent, Row, Typography, Form, FormInstance } from 'antd';
import { Card } from '../Card';
import { Heading } from '../Heading';
import { useState } from 'react';
import { CustomSelect } from '../CustomSelect';
import { useTranslation } from 'react-i18next';
import { AddNewLocationModal } from '../AddNewLocationModal';
import { LOCAL_EN } from '@/config';
import { useFetchPickupAddresses } from '@/hooksApi/usePickupAddress';
import { usePickupAddressStore } from '@/stores';
import { Store } from 'antd/es/form/interface';
import { getWarehouseAddressType } from '@/utils';
// import { selectData } from '@/utils/CustomSelectData';

const { Text } = Typography;

interface PickupDropCardProps {
  form: FormInstance; // Accept form instance as a prop
  title: string;
  option1Label: string;
  option2Label: string;
  collectDescription: string;
  dropoffDescription: string;
  previewOrderPrice?: (pickupAddressUID?: string) => void; // New prop for previewOrderPrice
  transformValuesToPayload: (values: Store) => Store; // New prop for transforming form values
}

interface SelectItem {
  uid: string;
  title: string;
  address_text: string;
}

// const typedSelectData: SelectItem[] = selectData;
const PickupDropCard = ({
  form, // Use the form instance to interact with the parent form
  title,
  option1Label,
  // option2Label,
  collectDescription,
  dropoffDescription,
  previewOrderPrice,
  transformValuesToPayload,
}: PickupDropCardProps) => {
  const [selectedValue, setSelectedValue] = useState('PICK_UP');
  const [addNewLocationModalOpen, setAddNewLocationModalOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [selectedUid, setSelectedUid] = useState<string | null>(null); // State for selected uid

  const handleRadioChange = (e: RadioChangeEvent) => {
    setSelectedValue(e.target.value);
    form.setFieldsValue({ saved_pick_up_address_uid: null });
    setSelectedUid(null);
  };

  const { clearPickupAddresses } = usePickupAddressStore();

  const { data: pickupAddresses, isLoading: pickupAddressLoading } = useFetchPickupAddresses();

  // const typedSelectData: SelectItem[] =
  //   pickupAddresses?.object?.items.map((item) => ({
  //     uid: item.uid,
  //     title: item.title,
  //     address_text: item.address_text,
  //   })) || [];
  // Filter to include only 'WAREHOUSE' type addresses

  const typedSelectData: SelectItem[] = getWarehouseAddressType(pickupAddresses?.object.items);

  // Set the initial value when the data is loaded
  useEffect(() => {
    if (typedSelectData.length > 0 && !selectedUid) {
      const firstUid = typedSelectData[0].uid;
      setSelectedUid(firstUid);
      form.setFieldsValue({ saved_pick_up_address_uid: firstUid });
    }
  }, [typedSelectData, selectedUid, form]);

  // const handleSelectPickupAddress = (uid: string) => {
  //   setSelectedUid(uid);
  //   form.setFieldsValue({ saved_pick_up_address_uid: uid });
  // };

  // Call previewOrderPrice when saved_pick_up_address_uid changes
  const handleSelectPickupAddress = (uid: string) => {
    setSelectedUid(uid);
    updatePreviewOrderPrice(uid);
  };

  const updatePreviewOrderPrice = (pickupAddressUID?: string) => {
    form.setFieldsValue({ saved_pick_up_address_uid: pickupAddressUID });

    previewOrderPrice?.(pickupAddressUID);
  };

  return (
    <Card>
      <Flex>
        <Heading
          title={title}
          fontSize='text-lg'
          fontWeight='font-bold'
          extraClassName='uppercase'
        />
        <Text className='text-colorError text-2xl mx-1'>*</Text>
      </Flex>
      <Form.Item name='pickupOrDropoff' initialValue='PICK_UP'>
        <Radio.Group className='w-full' value={selectedValue} onChange={handleRadioChange}>
          <Row gutter={[24, 16]} className='mt-[19.5px]'>
            <Col lg={12} md={24} xs={24}>
              <Flex
                className={`h-[81px] ${
                  selectedValue === 'PICK_UP'
                    ? 'bg-customYellowPrimary border border-solid border-customTunaPrimary'
                    : 'border border-solid border-customGrayShade4'
                } rounded-[10px] flex items-center justify-start pl-5`}
              >
                <Radio
                  value='PICK_UP'
                  className={`custom-radio ${
                    selectedValue === 'PICK_UP' ? 'text-customTunaPrimary' : 'text-customGrayShade5'
                  } flex items-center font-bold text-lg leading-[20.88px]`}
                >
                  {option1Label}
                </Radio>
              </Flex>
            </Col>
            {/*V0 remove*/}
            {/* <Col lg={12} md={24} xs={24}>
              <Flex
                className={`h-[81px] ${
                  selectedValue === 'DROP_OFF'
                    ? 'bg-customYellowPrimary border border-solid border-customTunaPrimary'
                    : 'border border-solid border-customGrayShade4'
                } rounded-[10px] flex items-center justify-start pl-5`}
              >
                <Radio
                  value='DROP_OFF'
                  className={`custom-radio ${
                    selectedValue === 'DROP_OFF'
                      ? 'text-customTunaPrimary'
                      : 'text-customGrayShade5'
                  } flex items-center font-bold text-lg leading-[20.88px]`}
                >
                  {option2Label}
                </Radio>
              </Flex>
            </Col> */}
          </Row>
        </Radio.Group>
      </Form.Item>

      {selectedValue === 'PICK_UP' ? (
        <Row>
          <Col lg={24} md={24} xs={24}>
            {typedSelectData.length !== 0 ? (
              <Form.Item name='saved_pick_up_address_uid' initialValue={selectedUid}>
                <CustomSelect
                  placeholder={t('select_pickup')}
                  isLoading={pickupAddressLoading}
                  data={typedSelectData}
                  title={collectDescription}
                  titleStyle='font-normal text-base leading-[20.48px] text-customTunaPrimary mb-[14px]'
                  parentStyle='mt-3'
                  onSelect={handleSelectPickupAddress} // Pass uid to form
                />
              </Form.Item>
            ) : (
              <Flex vertical>
                <Text className='font-normal text-base my-3 leading-[20.48px] text-customTunaPrimary'>
                  {collectDescription}
                </Text>
                <Flex
                  className={`rounded-[10px] gap-x-1 py-[30px] ${
                    i18n.language === LOCAL_EN ? 'pl-4' : 'pr-4'
                  } pl-4 border border-solid border-customGrayShade4`}
                >
                  <Text className='font-normal text-base leading-[20.48px] text-customTunaPrimary'>
                    {t('there_is_no_address_in_your_list')}
                  </Text>
                  <Text
                    onClick={() => {
                      setAddNewLocationModalOpen((prev) => !prev);
                      clearPickupAddresses();
                    }}
                    className='font-normal cursor-pointer text-base leading-[20.48px] capitalize text-customBlue'
                  >
                    {t('address_list')}
                  </Text>
                </Flex>
              </Flex>
            )}
          </Col>
        </Row>
      ) : (
        <Row className='mt-3'>
          <Col lg={24} md={24} xs={24}>
            <Text className='font-normal text-base leading-[20.48px] text-customTunaPrimary mb-[14px]'>
              {dropoffDescription}
            </Text>
          </Col>
        </Row>
      )}

      <AddNewLocationModal
        updatePreviewOrderPrice={updatePreviewOrderPrice}
        visible={addNewLocationModalOpen}
        onClose={() => setAddNewLocationModalOpen(false)}
      />
    </Card>
  );
};

export default PickupDropCard;
