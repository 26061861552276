import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, FormInstance } from 'antd';
import PackageSizeCard from './PackageSizeCard';
import { PaymentCard } from './PaymentCard';
import { PaymentType, Preview, ShipmentFeeType } from '@/types';

interface PackageAndPaymentCardProps {
  form: FormInstance;
  onPackageSizeChange: (size: string) => void;
  orderPrice: Preview;
  orderPriceLoading: boolean;
  paymentType?: PaymentType;
  shipmentFeeType?: ShipmentFeeType;
  packageSize?: string;
  onCustomShipmentFeeChange: (shipmentFee?: number) => void;
  customShipmentFee?: number;
}
const PackageAndPaymentCard: React.FC<PackageAndPaymentCardProps> = ({
  form,
  onPackageSizeChange,
  orderPrice,
  orderPriceLoading,
  paymentType,
  shipmentFeeType,
  packageSize,
  onCustomShipmentFeeChange,
  customShipmentFee,
}) => {
  const { t } = useTranslation();

  return (
    <div className='sticky top-4  w-full '>
      <PackageSizeCard
        title={t('package_size')}
        form={form}
        packageSizeProp={packageSize}
        onPackageSizeChange={onPackageSizeChange}
      />

      <PaymentCard
        form={form}
        title={t('payment')}
        orderTitle={t('order_title')}
        codTitle={t('cod')}
        prepaidTitle={t('prepaid')}
        shipmentFeeTitle={t('shipment_fee')}
        customerTitle={t('customer')}
        merchantTitle={t('merchant')}
        walletBalanceText={t('wallet_balance')}
        availableText={t('available')}
        iqdText={t('iqd')}
        discountPlaceholder={t('discount_code')}
        orderPrice={orderPrice}
        orderPriceLoading={orderPriceLoading}
        paymentType={paymentType}
        shipmentFeeType={shipmentFeeType}
        onCustomShipmentFeeChange={onCustomShipmentFeeChange}
        customShipmentFee={customShipmentFee}
      />
    </div>
  );
};

export default PackageAndPaymentCard;
