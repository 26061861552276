import {
  useCreateWebhooks,
  useFetchWebhooksByUid,
  useFetchWebhooksEvents,
  useUpdateWebhooks,
} from '@/hooksApi';
import { Button, Flex, Form, Input, message, Modal, Select, Tag, Typography } from 'antd';
import { Store } from 'antd/es/form/interface';
import { useTranslation } from 'react-i18next';
import TriggerSelector from './TriggerSelector';
import PropertiesHeaderSection from './PropertiesHeaderSection';
import { useEffect } from 'react';
import { WebHooksDataResponse } from '@/types';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
  webHookUID?: string;
  onClose: () => void;
}
const WebHookModal = ({ webHookUID, onClose }: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { data: webhookData } = useFetchWebhooksByUid(webHookUID);
  const { mutate: createWebhooks } = useCreateWebhooks();
  const { mutate: updateWebhooks } = useUpdateWebhooks();

  useEffect(() => {
    if (webhookData?.object) {
      form.setFieldsValue({
        url: webhookData.object.url,
        properties: webhookData.object.properties,
        triggers: webhookData.object.events?.map((event) => event.uid) ?? [],
      });
    }
    return () => {
      form.resetFields();
    };
  }, [webHookUID, webhookData?.object, form]);

  const onFinish = async (values: Store) => {
    if (webHookUID === '') {
      createWebhooks(
        {
          event_uids: values.triggers,
          properties: values.properties,
          url: values.url,
        },
        {
          onSuccess: () => {
            onClose();
            queryClient.invalidateQueries(['webhooks']);
          },
        },
      );
    } else {
      updateWebhooks(
        {
          event_uids: values.triggers,
          properties: values.properties,
          url: values.url,
          uid: webHookUID ?? '',
        },
        {
          onSuccess: () => {
            onClose();
            queryClient.invalidateQueries(['webhooks']);
          },
        },
      );
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Form submission failed:', errorInfo);
    message.error(t('submission_failed'));
  };

  return (
    <Modal
      className='rounded-[20px] bg-white border   border-customGrayShade3'
      title={
        <Flex
          className='font-bold text-lg leading-5  text-customTunaPrimary '
          style={{ borderBottom: '1px solid #E1E1E3', paddingBottom: '20px' }}
        >
          {t('add_new_webHook')}
        </Flex>
      }
      width={760}
      centered
      footer={null}
      open={webHookUID !== undefined}
      onOk={onClose}
      onCancel={onClose}
    >
      <Form
        initialValues={{
          url: webhookData?.object.url ?? '',
          triggers: webhookData?.object.events?.map((event) => event.uid) ?? [],
        }}
        form={form}
        name='webhook-form'
        className='py-2'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Flex vertical gap={16}>
          <Typography.Text className='text-base'>{t('by_customer_data')}</Typography.Text>

          <Form.Item name='url' rules={[{ required: true, message: t('this_field_is_required') }]}>
            <Input
              suffix={<span className='text-customRed text-base'>*</span>}
              variant='outlined'
              placeholder={t('url')}
              className='custom-input border custom-select-placeholder border-solid border-customGrayShade5 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg w-full'
            />
          </Form.Item>
        </Flex>

        <TriggerSelector />

        <PropertiesHeaderSection
          formInstance={form}
          webhookUID={webHookUID}
          properties={[...(webhookData?.object.properties ?? [])]}
        />

        <Flex className='mt-8' justify='end' gap={16}>
          <Button
            className='px-10 py-6 rounded-lg uppercase font-semibold border-customTunaPrimary'
            onClick={onClose}
          >
            {t('cancel')}
          </Button>
          <Button
            type='primary'
            className='px-10 py-6 rounded-lg text-customTunaPrimary uppercase font-semibold'
            loading={false}
            htmlType='submit'
          >
            {t('save')}
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
};

export default WebHookModal;
