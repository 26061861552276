//ExclamationTriangle
import { SVGProps } from 'react';

interface ErrorModalIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

//TODO: needs optimization
const ErrorModalIcon = ({
  width = 80,
  height = 80,
  color = 'white',
  ...props
}: ErrorModalIconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...props}>
    <rect x='0.5' width='80' height='80' rx='40' fill='#DC3545' />
    <path
      d='M31.572 29.412L40.6176 38.5467L49.6625 29.412C49.7126 29.3615 49.7808 29.333 49.852 29.333H51.9024C52.0496 29.333 52.169 29.4524 52.169 29.5997C52.169 29.6699 52.1413 29.7374 52.0918 29.7873L42.0179 39.9609L52.1678 50.2121C52.2714 50.3167 52.2706 50.4855 52.1659 50.5892C52.116 50.6386 52.0486 50.6663 51.9783 50.6663H49.9286C49.8574 50.6663 49.7892 50.6379 49.7391 50.5873L40.6176 41.3751L31.4954 50.5873C31.4454 50.6379 31.3771 50.6663 31.306 50.6663H29.2569C29.1096 50.6663 28.9902 50.5469 28.9902 50.3997C28.9902 50.3294 29.018 50.262 29.0674 50.2121L39.2172 39.9609L29.1434 29.7873C29.0397 29.6827 29.0406 29.5138 29.1452 29.4102C29.1952 29.3607 29.2626 29.333 29.3329 29.333H31.3825C31.4537 29.333 31.5219 29.3615 31.572 29.412Z'
      fill={color}
    />
  </svg>
);

export default ErrorModalIcon;
