import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppRouter } from './routes';
import { ConfirmModal } from './components';

const App: React.FC = () => {
  return (
    <Router>
      <AppRouter />
      <ConfirmModal />
    </Router>
  );
};

export default App;
