import { Drawer, Dropdown, Flex, MenuProps } from 'antd';
import { CloseOutline, MoreOutline } from '../Icons';
import { useTranslation } from 'react-i18next';
import { LOCAL_EN, THEME_VARIABLE } from '@/config';

import { Heading } from '../Heading';
import { NotificationCard } from '../NotificationCard';
import useLanguageStore from '@/stores/useLanguageStore';

interface Props {
  drawerVisible: boolean;
  setDrawerVisible: (value: boolean) => void;
}
const NotificationDrawer = ({ drawerVisible, setDrawerVisible }: Props) => {
  const { t } = useTranslation();

  const { language } = useLanguageStore();

  const iconItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Heading
          fontWeight='font-normal'
          fontSize='text-sm'
          extraClassName='leading-[18px] capitalize'
          title={t('clear_all')}
          leadingIcon={
            <CloseOutline width={20} height={20} color={THEME_VARIABLE.customGrayShade5} />
          }
          rotation={false}
        />
      ),
    },
  ];

  const drawerContent = (
    <>
      <NotificationCard
        title={t('announcements')}
        time='19 Sep, 24'
        iconColor={THEME_VARIABLE.customBlue}
        description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas eu enim risus. In ac felis id ligula accumsan rutrum vitae vitae turpis. Duis id ultrices magna. Donec ac condimentum sapien, eu sodales ipsum.'
      />
      <NotificationCard
        title={t('order_delivered')}
        time='19 Sep, 24'
        iconColor={THEME_VARIABLE.customGreen}
        description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas eu enim risus. In ac felis id ligula accumsan rutrum vitae vitae turpis. Duis id ultrices magna. Donec ac condimentum sapien, eu sodales ipsum.'
      />
      <NotificationCard
        title={t('order_returned')}
        time='19 Sep, 24'
        iconColor={THEME_VARIABLE.customRed}
        description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas eu enim risus. In ac felis id ligula accumsan rutrum vitae vitae turpis. Duis id ultrices magna. Donec ac condimentum sapien, eu sodales ipsum.'
      />
    </>
  );

  return (
    <Drawer
      closeIcon={<CloseOutline />}
      title={
        <Flex className='justify-between items-center'>
          {t('notifications')}
          <Flex className='items-center gap-x-[10px]'>
            <Dropdown
              menu={{ items: iconItems }}
              trigger={['click']}
              rootClassName='bottomSheet'
              placement='bottomLeft'
            >
              <MoreOutline
                width={24}
                height={24}
                className='cursor-pointer '
                color={THEME_VARIABLE.customTunaPrimary}
              />
            </Dropdown>{' '}
            <CloseOutline
              width={24}
              height={24}
              color={THEME_VARIABLE.customTunaPrimary}
              className='cursor-pointer'
              onClick={() => setDrawerVisible(false)}
            />
          </Flex>
        </Flex>
      }
      placement={language === LOCAL_EN ? 'right' : 'left'}
      closable={false}
      onClose={() => setDrawerVisible(false)}
      open={drawerVisible}
      width={270}
    >
      {drawerContent}
    </Drawer>
  );
};

export default NotificationDrawer;
