import { useConfirmModalStore } from '@/stores';
import { Button, Flex, Modal, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { WarningModalIcon, InfoModalIcon, ErrorModalIcon, SuccessModalIcon } from '../Icons';

export enum ConfirmTypeEnum {
  WARNING,
  SUCCESS,
  Error,
  INFO,
}

export const ConfirmModal = () => {
  const {
    isOpen,
    title,
    content,
    description,
    approveText,
    cancelText,
    onConfirm,
    onCancel,
    closeModal,
    confirmType = ConfirmTypeEnum.WARNING,
  } = useConfirmModalStore();

  const { t } = useTranslation();

  const modalData = [
    { headerTitle: null, icon: <WarningModalIcon /> },
    { headerTitle: 'SUCCESS', icon: <SuccessModalIcon /> },
    { headerTitle: 'ATTENTION', icon: <ErrorModalIcon /> },
    { headerTitle: null, icon: <InfoModalIcon /> },
  ];

  const handleConfirm = () => {
    if (onConfirm) onConfirm();
    closeModal();
  };

  const handleCancel = () => {
    if (onCancel) onCancel();
    closeModal();
  };

  return (
    <Modal
      title={
        modalData[confirmType].headerTitle ? (
          <div
            className='pb-4 border-gray-200 border-b-[1px]'
            style={{ borderBottom: 'solid 1px ' }}
          >
            {modalData[confirmType].headerTitle}
          </div>
        ) : (
          <div className='h-6' />
        )
      }
      open={isOpen}
      footer={
        <>
          {cancelText && <Button onClick={handleCancel}>{cancelText}</Button>}
          <Button type='primary' onClick={handleConfirm}>
            {approveText ?? t('confirm')}
          </Button>
        </>
      }
    >
      <Flex vertical gap='24px' className='w-full py-5 items-center'>
        {modalData[confirmType].icon}
        {content ?? (
          <>
            {title && (
              <Typography.Text style={{ fontSize: '1.5rem' }}>
                {title.toLocaleUpperCase()}
              </Typography.Text>
            )}
            <Flex vertical gap='8px' className='w-full my-2'>
              {description && Array.isArray(description) ? (
                description.map((item, index) => (
                  <Typography.Text key={index} style={{ fontSize: '1.1rem' }}>
                    {item}
                  </Typography.Text>
                ))
              ) : (
                <Typography.Text style={{ fontSize: '1.05rem' }}>{description}</Typography.Text>
              )}
            </Flex>
          </>
        )}
      </Flex>
    </Modal>
  );
};
