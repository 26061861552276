import { ConfirmTypeEnum } from '@/components';
import { create } from 'zustand';

interface ConfirmParams {
  title?: string;
  description?: string | string[];
  content?: React.ReactNode;
  onConfirm: () => void;
  onCancel?: () => void;
  approveText?: string;
  cancelText?: string;
  confirmType?: ConfirmTypeEnum;
}
interface ConfirmModal extends ConfirmParams {
  isOpen: boolean;
  openConfirm: ({ title, content, onConfirm, onCancel, confirmType }: ConfirmParams) => void;
  closeModal: () => void;
}

const defaultValue: ConfirmParams = {
  title: undefined,
  description: undefined,
  content: undefined,
  approveText: undefined,
  cancelText: undefined,
  confirmType: undefined,
  onConfirm: () => {},
  onCancel: undefined,
};

const useConfirmModalStore = create<ConfirmModal>((set) => ({
  isOpen: false,
  ...defaultValue,
  openConfirm: (params: ConfirmParams) =>
    set({
      isOpen: true,
      ...params,
    }),
  closeModal: () => set({ ...defaultValue, isOpen: false }),
}));

export default useConfirmModalStore;
