//ExclamationTriangle
import { SVGProps } from 'react';

interface SuccessModalIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

//TODO: needs optimization
const SuccessModalIcon = ({
  width = 80,
  height = 80,
  color = 'white',
  ...props
}: SuccessModalIconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...props}>
    <rect x='0.5' width='80' height='80' rx='40' fill='#2BB45D' />
    <path
      d='M54.0393 32.4561L38.6449 47.7108C38.2558 48.0964 37.6249 48.0964 37.2357 47.7108C37.2021 47.6775 37.1714 47.6424 37.1436 47.6058C37.1071 47.5787 37.0719 47.5484 37.0384 47.5153L26.9598 37.528C26.8552 37.4244 26.8544 37.2555 26.9581 37.1509C27.0082 37.1004 27.0764 37.0719 27.1475 37.0719H29.2078C29.2781 37.0719 29.3456 37.0997 29.3955 37.1492L37.9399 45.6157L51.6036 32.0772C51.6536 32.0278 51.721 32 51.7913 32H53.8516C53.9989 32 54.1183 32.1194 54.1183 32.2667C54.1183 32.3378 54.0898 32.406 54.0393 32.4561Z'
      fill={color}
    />
  </svg>
);

export default SuccessModalIcon;
