import React, { useEffect, useState } from 'react';
import { Button, Flex, Form, message, Modal, Spin } from 'antd';
import EditCustomerDetails from './EditCustomerDetails';
import { useTranslation } from 'react-i18next';
import EditDeliveryInstruction from './EditDeliveryInstruction';
import EditOrderId from './EditOrderId';
import EditProductDetails from './EditProductDetails';
import { useEditModalTotalPriceStore, useEditRowProductModal, useImportTableUid } from '@/hooks';
import { useOrderExcelRowByUID, useUpdateOrderExcelRow } from '@/hooksApi/useImport';
import { Heading } from '../Heading';
import EditPackageAndPayment from './EditPackageAndPayment';
import { usePreviewOrderPrice } from '@/hooksApi/useOrder';
import { Store } from 'antd/es/form/interface';
import {
  arrayToCommaSeparatedObject,
  attachCountryCodeToPhone,
  commaSeparatedObjectToArray,
  convertArabicToEnglishNumbers,
  getDialCodeByCountryCode,
  parsePhoneNumber,
} from '@/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useContactsStore } from '@/stores/contactStore';
import { useFetchListProvinces, useFetchAreaByProvinceCode } from '@/hooksApi/useRegion';
import { CustomerDetailsCard } from '../OrderForm';
import { PaymentType, ShipmentFeeType } from '@/types';

interface ModalProps {
  visible: boolean;
  onClose: () => void;
  keyValue?: string;
}

const EditModal: React.FC<ModalProps> = ({ visible, onClose, keyValue }) => {
  const [selectedProvinceCode, setSelectedProvinceCode] = useState<string | undefined>(undefined);

  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const tableUid = useImportTableUid((state) => state.importTableUid); // Access the Table UID setter from Zustand
  const { mutate: updateOrder, isLoading: updateLoading } = useUpdateOrderExcelRow(
    tableUid,
    keyValue || '',
  ); // Initialize the mutation hook
  // const { mutate: previewOrderPrice, isLoading: orderPriceLoading } = usePreviewOrderPrice();
  // Fetch order row using the custom hook
  const { data: orderRow, isLoading: orderRowLoading } = useOrderExcelRowByUID(
    tableUid,
    keyValue || '',
  );

  const { data: provinceList, isLoading: provinceListLoading } = useFetchListProvinces();

  const { data: areaList, isLoading: areaListLoading } = useFetchAreaByProvinceCode(
    selectedProvinceCode!,
  );
  const regionUid = useContactsStore((state) => state.regionUid);

  useEffect(() => {
    if (!areaListLoading && areaList && areaList?.object.length > 0) {
      const area = areaList.object.find((item) => item.uid === orderRow?.object.region?.uid);

      form.setFieldsValue({ region_uid: area?.uid });
    }
  }, [areaListLoading, areaList, orderRow, keyValue]);

  useEffect(() => {
    form.resetFields();
    if (orderRow) {
      const provinceCode = provinceList?.object.find(
        (item) => item.province_code === orderRow.object.region?.province_code,
      )?.province_code;
      const area_uid =
        provinceCode && provinceCode === selectedProvinceCode
          ? orderRow.object.region?.uid
          : undefined;
      if (provinceCode && provinceCode !== selectedProvinceCode)
        setSelectedProvinceCode(provinceCode);

      const primaryPhoneInfo = parsePhoneNumber(orderRow.object.phone);
      const secondaryPhoneInfo = parsePhoneNumber(orderRow.object.secondary_phone ?? undefined);

      // Prepopulate the form with fetched order data
      form.setFieldsValue({
        name: orderRow?.object?.full_name || '',
        address: orderRow.object?.address_text || '',
        phoneNumberPrimary: primaryPhoneInfo.phone || '',
        countryCodePrimary: primaryPhoneInfo.countryCode,
        countryCodeSecondary: secondaryPhoneInfo?.countryCode,
        secondaryPhoneNumber: secondaryPhoneInfo?.phone,
        deliveryInstruction: orderRow?.object?.description || '',
        packageSize: orderRow?.object?.size || '',
        isFragile: orderRow?.object?.is_fragile,
        CodOrPrepaid: orderRow?.object?.payment_type,
        CustomerOrMerchant: orderRow?.object?.shipment_fee_type,
        orderId: orderRow?.object?.custom_id,
        pickupOrDropoff: orderRow?.object?.pick_up_type,
        province: provinceCode,
        region_uid: area_uid,
        // saved_pick_up_address_uid: orderRow?.object?.pick_up_address.uid,
      });
      // setRegionnUid(orderData?.object.region?.uid);
      // setPickupAddressUid(orderData?.object?.pick_up_address.uid);
      const commaSeparatedObj = {
        product_title: orderRow?.object?.product_title,
        product_price: orderRow?.object?.product_price,
        product_quantity: orderRow?.object?.product_quantity,
      };
      const productsArrayFromObject = commaSeparatedObjectToArray(commaSeparatedObj);
      // Set the products if available
      if (productsArrayFromObject) {
        resetProducts(productsArrayFromObject); // Reset products if data is available
      }
    }
  }, [orderRow, form, keyValue]); // Include 'form' in the dependency array
  /*HOOK TO CREATE NEW PRODUCT AND ARRAY OF ALL PRODUCTS  */
  const {
    products,
    totalSum, // Expose the total sum
    newProduct,
    handleAddProduct,
    handleDeleteProduct,
    handleChangeNewProduct,
    handleChangeProduct,
    resetProducts,
  } = useEditRowProductModal();
  const setTotalProductsPrice = useEditModalTotalPriceStore((state) => state.setTotalProductsPrice);
  if (totalSum) {
    setTotalProductsPrice(totalSum);
  }

  const commaSeparatedObject = arrayToCommaSeparatedObject(products);

  const transformValuesToUpdateOrder = (values: Store) => {
    // Ensure the phone number is correctly formatted
    const primaryPhoneNumber = values?.phoneNumberPrimary
      ? attachCountryCodeToPhone(values?.phoneNumberPrimary, values?.countryCodePrimary)
      : undefined;

    const secondaryPhoneNumber = values?.secondaryPhoneNumber
      ? attachCountryCodeToPhone(values?.secondaryPhoneNumber, values?.countryCodeSecondary)
      : undefined;

    return {
      is_fragile: values?.isFragile || false,
      shipment_fee_type: values?.CustomerOrMerchant || ShipmentFeeType.BY_MERCHANT,
      payment_type: values?.CodOrPrepaid || PaymentType.COLLECT_ON_DELIVERY,
      pick_up_type: values?.pickupOrDropoff || 'PICK_UP',
      description: values?.deliveryInstruction || '',
      custom_id: values?.orderId || '', // Custom ID
      // saved_pick_up_address_uid: values?.saved_pick_up_address_uid || '',
      size: values?.packageSize || 'S', // Default size if not selected
      full_name: values?.name || '',
      address_text: values?.address || '',
      phone: primaryPhoneNumber,
      secondary_phone: secondaryPhoneNumber,
      product_title: commaSeparatedObject.product_title,
      product_quantity: commaSeparatedObject.product_quantity,
      product_price: commaSeparatedObject.product_price,
      region_uid: values.region_uid,
    };
  };
  // Form submission handler
  const onFinish = async (values: Store) => {
    // Transform the values before sending
    const orderPayload = transformValuesToUpdateOrder(values);

    updateOrder(orderPayload, {
      onSuccess: () => {
        queryClient.refetchQueries(['import']);
        message.success(t('updated_successfully'));
        handleCloseModal(); // Close the modal after submission
      },
    });
  };

  // Form submission error handler
  const onFinishFailed = (errorInfo: any) => {
    console.error('Form submission failed:', errorInfo);
    message.error(t('submission_failed'));
  };

  const handleCloseModal = () => {
    onClose();
  };

  const handleSetSelectedProvinceCode = (provinceCode?: string) => {
    form.setFieldsValue({ province: provinceCode });
    form.setFieldsValue({ region_uid: undefined });
    form.setFieldValue('region_uid', undefined);
    setSelectedProvinceCode(provinceCode);
  };

  return (
    <Modal
      className='rounded-[20px] bg-white border   border-customGrayShade3'
      title={
        <Flex
          className='font-bold text-lg leading-5  text-customTunaPrimary '
          style={{ borderBottom: '1px solid #E1E1E3', paddingBottom: '20px' }}
        >
          {t('edit')}
        </Flex>
      }
      width={760}
      centered
      footer={null}
      open={visible}
      onOk={handleCloseModal}
      onCancel={handleCloseModal}
    >
      {' '}
      {orderRowLoading ? (
        <Spin size='small' className='flex justify-center items-center h-screen' />
      ) : (
        <Form
          form={form}
          name='edit-row-order'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          // onValuesChange={onValuesChange} // Track form value changes
        >
          <CustomerDetailsCard
            form={form}
            selectedProvinceCode={selectedProvinceCode}
            setSelectedProvinceCode={handleSetSelectedProvinceCode}
          />

          <EditDeliveryInstruction
            title={t('delivery_instruction')}
            description={t('lorem_ipsum')}
            placeholder={t('do_not_ring_door')}
          />
          <EditOrderId
            title={t('order_id')}
            description={t('if_you_do_not')}
            placeholder={t('order_id_custom')}
          />
          <EditProductDetails
            title={t('product_details')}
            placeholders={{
              title: t('product_name'),
              quantity: t('qty'),
              price: t('price'),
            }}
            products={products}
            newProduct={newProduct}
            handleAddProduct={handleAddProduct}
            handleDeleteProduct={handleDeleteProduct}
            handleChangeNewProduct={handleChangeNewProduct}
            handleChangeProduct={handleChangeProduct}
          />
          <EditPackageAndPayment
            form={form}
            CodOrPrepaid={orderRow?.object?.payment_type} // Pass CodOrPrepaid from orderData
            CustomerOrMerchant={orderRow?.object?.shipment_fee_type} // Pass CustomerOrMerchant from orderData
            packageSize={orderRow?.object?.size}
          />
          <Flex className='flex justify-end w-full gap-x-4 pt-2 '>
            <Button
              style={{
                paddingTop: '26px',
                paddingBottom: '26px',
              }}
              className='px-[26px] rounded-lg'
              type='default'
              onClick={handleCloseModal}
            >
              <Heading
                title={t('cancel')}
                fontSize='text-sm'
                fontWeight='font-bold'
                extraClassName='leading-5 uppercase'
              />
            </Button>
            <Button
              style={{ paddingTop: '26px', paddingBottom: '26px' }}
              type='primary'
              htmlType='submit'
              className='px-[26px] rounded-lg'
              loading={updateLoading}
            >
              <Heading
                title={t('update')}
                fontSize='text-sm'
                fontWeight='font-bold'
                extraClassName='leading-5 uppercase'
              />
            </Button>
          </Flex>
        </Form>
      )}
    </Modal>
  );
};

export default EditModal;
