import { Button } from 'antd';
import { Heading } from '../../Heading';
import { useNavigate } from 'react-router-dom';

interface Props {
  label: string;
  icon: React.ComponentType<{ width: number; height: number; className?: string }>;
  isPrimary?: boolean;
  navigateLink?: string;
  onClick?: () => void;
}

const OrderHeaderButton = ({ icon: Icon, label, isPrimary, navigateLink, onClick }: Props) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    if (navigateLink) navigate(navigateLink);
    else onClick?.();
  };

  return (
    <Button
      className={`rounded-md ${isPrimary ? '' : 'border-solid border-customTunaPrimary'}`}
      onClick={handleOnClick}
      type={isPrimary ? 'primary' : 'default'}
    >
      <Heading
        leadingIcon={<Icon width={20} height={20} className='mt-1' />}
        title={label}
        fontWeight='font-bold'
        extraClassName='leading-5'
      />
    </Button>
  );
};

export default OrderHeaderButton;
