import { Upload, Typography, Row, Col, Flex, Card as CardAnt, message, Spin } from 'antd';
import { RcFile } from 'antd/es/upload/interface';
import { BarIcon, Card, Heading, TrashIcon } from '@/components';
import { useTranslation } from 'react-i18next';
import { useUploadExcel } from '@/hooksApi/useImport';
import { mutateFunctionType } from '@/hooks/useFileUpload';

const { Dragger } = Upload;
const { Paragraph, Link } = Typography;

interface StepProps {
  uploadedFile: RcFile | null;
  uploadFile: (mutateFunction: mutateFunctionType, file?: RcFile) => Promise<void>;
  handleBeforeUpload: (file: RcFile) => string | false;
  handleResetUploadedFile: () => void;
}

const Step1 = ({
  handleResetUploadedFile,
  uploadedFile,
  handleBeforeUpload,
  uploadFile,
}: StepProps) => {
  const { t } = useTranslation();
  const { mutate: UploadExcel, isLoading: loadingExcel } = useUploadExcel();

  const handleDeleteFile = () => {
    handleResetUploadedFile();

    if (uploadedFile) {
      message.info(`${uploadedFile?.name} ${t('file_removed')}`);
    }
  };

  const handleUpload = (file: RcFile) => {
    const validationErrorReason = handleBeforeUpload(file);

    if (!validationErrorReason) uploadFile(UploadExcel, file);
  };
  return (
    <Row gutter={24}>
      <Col md={14} xs={24}>
        <Card>
          <Heading
            title={t('import_instructions')}
            level={4}
            fontWeight='font-bold'
            extraClassName='mb-6'
          />
          <Paragraph>
            <strong>1. {t('download_template')}:</strong> {t('start_download')}{' '}
            <Link href='/boxy-bulk-import.csv' target='_blank'>
              {t('csv_template')}
            </Link>
            .
          </Paragraph>
          <Paragraph>
            <strong>2. {t('fill_data')}:</strong> {t('use_first')}.
          </Paragraph>
          <Paragraph>
            <strong>3. {t('upload_file')}:</strong> {t('after_upload')}.
          </Paragraph>
          <Paragraph>
            <strong>4. {t('mandatory_fields')}:</strong> {t('ensure')}.
          </Paragraph>
          <Paragraph>
            <strong>5. {t('import_limit')}:</strong> {t('can_import')}.
          </Paragraph>
          <CardAnt className='mt-4 bg-customGrayShade1'>
            <Heading title={t('how_to_work')} level={5} fontWeight='font-bold' />
            <Paragraph>
              {t('importing')} <Link href='#'>{t('csv_guide')}</Link>.
            </Paragraph>
          </CardAnt>
        </Card>
      </Col>
      <Col md={10} xs={24}>
        <Card>
          <Heading
            title={t('upload_file')}
            level={4}
            fontWeight='font-bold'
            extraClassName='mb-6'
          />
          {loadingExcel ? (
            <Spin />
          ) : (
            <Dragger
              name={t('upload_file')}
              multiple={false}
              maxCount={1}
              beforeUpload={handleUpload}
              accept='.csv, .xlsx'
            >
              <BarIcon className='my-4' />
              <Flex vertical align='center' justify='center'>
                <Flex gap={1}>
                  <Heading
                    title={t('click_to_upload')}
                    fontWeight='font-bold'
                    fontSize='text-base'
                    extraClassName='underline'
                  />
                  <Heading title={t('drag_drop')} fontWeight='font-bold' fontSize='text-base' />
                </Flex>
                <Heading
                  title={t('maximum_size')}
                  fontSize='text-sm'
                  color='text-customGrayShade5'
                />
              </Flex>
            </Dragger>
          )}
          {uploadedFile && (
            <Flex className='mt-4 gap-2 flex justify-between'>
              <Flex>
                <strong>{t('file_name')}:</strong> <Flex className='mx-2'>{uploadedFile.name}</Flex>
              </Flex>
              <TrashIcon onClick={handleDeleteFile} color='red' />
            </Flex>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default Step1;
