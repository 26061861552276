import { SVGProps } from 'react';

interface EyeInvisibleIconProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const EyeInvisibleIcon = ({
  width = 24,
  height = 25,
  color = '#38383E',
  ...props
}: EyeInvisibleIconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 25'
    width={width}
    height={height}
    fill='none'
    {...props}
  >
    <path
      d='M21.9997 7.96819L22 9.56984C22 9.627 21.9756 9.68143 21.9328 9.71939C20.9488 10.5937 20.1736 11.21 19.6073 11.5682L21.5346 13.8081C21.6066 13.8919 21.5972 14.0182 21.5134 14.0902C21.5122 14.0913 21.5109 14.0923 21.5096 14.0934L20.6703 14.7803C20.5863 14.849 20.4628 14.8382 20.392 14.7559L18.2859 12.3076C17.2205 12.8306 16.0774 13.2248 14.8778 13.4693L15.9076 16.2288C15.9462 16.3323 15.8936 16.4475 15.7901 16.4861C15.7891 16.4865 15.7881 16.4868 15.7871 16.4872L14.7546 16.8537C14.6517 16.8902 14.5385 16.8375 14.5003 16.7352L13.3653 13.6939C12.9161 13.7364 12.4606 13.758 12 13.758C11.4238 13.758 10.8557 13.7241 10.2977 13.6581L9.14945 16.7352C9.11127 16.8375 8.99808 16.8902 8.89517 16.8537L7.86268 16.4872C7.75859 16.4502 7.70416 16.3359 7.74111 16.2318C7.74147 16.2308 7.74184 16.2298 7.74222 16.2288L8.79823 13.3993C7.71655 13.1525 6.68313 12.7833 5.71414 12.3076L3.608 14.7559C3.53722 14.8382 3.41372 14.849 3.32972 14.7803L2.49035 14.0934C2.40487 14.0234 2.39229 13.8974 2.46224 13.8119C2.46328 13.8106 2.46434 13.8094 2.46541 13.8081L4.39269 11.5682C3.82635 11.21 3.05115 10.5937 2.0671 9.71933C2.02443 9.68134 2 9.62694 2 9.56982V7.98144C2 7.87098 2.08954 7.78144 2.2 7.78144C2.26011 7.78144 2.31704 7.80847 2.35502 7.85507L2.41103 7.92378L2.50897 8.03759C4.80141 10.6439 8.20299 12.2951 12 12.2951C15.8045 12.2951 19.212 10.6374 21.5045 8.0222L21.6545 7.84589C21.722 7.76253 21.8444 7.74972 21.9278 7.81728C21.9733 7.85416 21.9997 7.90961 21.9997 7.96819Z'
      fill={color}
    />
  </svg>
);

export default EyeInvisibleIcon;
