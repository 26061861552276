import { Button, DatePicker, Flex, Modal, Tag, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseCircleIcon } from '../../../Icons';
import { LOCAL_EN } from '@/config';
import { useCallback, useState } from 'react';
import { Heading } from '../../../Heading';
import dayjs, { Dayjs } from 'dayjs';
import { PlusOutlined, CheckOutlined } from '@ant-design/icons';
import { groupStatusesByMerchantTitle } from '@/utils/getStatus';

interface ExportModalProps {
  isOpen: boolean;
  onClose: () => void;
  onExport: (
    selectedDateRange?: string[],
    selectedMonthRange?: string[],
    selectedStatuses?: string[],
  ) => void;
}

const exportStatusList = groupStatusesByMerchantTitle();

export const ExportModal = ({ isOpen, onClose, onExport }: ExportModalProps) => {
  const [selectedDateRange, setSelectedDateRange] = useState<[Dayjs, Dayjs] | undefined>(undefined);
  const [selectedMonthRange, setSelectedMonthRange] = useState<[Dayjs, Dayjs] | undefined>(
    undefined,
  );
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

  const { t, i18n } = useTranslation();

  const formatDate = (dates?: [Dayjs, Dayjs]) => {
    if (dates)
      return [
        dates?.[0].startOf('day').format('YYYY-MM-DD HH:mm'),
        dates?.[1].endOf('day').format('YYYY-MM-DD HH:mm'),
      ];

    return undefined;
  };

  const handleExport = () => {
    const formattedDateRange = formatDate(selectedDateRange);
    const formattedMonthRange = formatDate(selectedMonthRange);

    const selectedOrderStatus = selectedStatuses.flatMap((item) => exportStatusList[item]);

    onExport(formattedDateRange, formattedMonthRange, selectedOrderStatus);
    handleClose();
  };

  const resetModal = () => {
    setSelectedDateRange(undefined);
    setSelectedMonthRange(undefined);
    setSelectedStatuses([]);
  };

  const handleClose = () => {
    onClose();
    resetModal();
  };

  const handleChange = (tag: string, checked: boolean) => {
    const nextSelectedTags = checked
      ? [...selectedStatuses, tag]
      : selectedStatuses.filter((t) => t !== tag);
    setSelectedStatuses(nextSelectedTags);
  };

  const renderStatusTags = useCallback(() => {
    return Object.entries(exportStatusList).map<React.ReactNode>((tag) => {
      const [groupName, subGroupList] = tag;

      return (
        <Tag.CheckableTag
          className={`rounded-full py-1 px-3 cursor-pointer flex gap-1 text-base bg-gray-200 hover:text-[#2BB45D]
          ${selectedStatuses.includes(groupName) ? '!bg-white border-[#2BB45D] text-[#2BB45D] hover:border-white' : ''}`}
          key={groupName}
          checked={selectedStatuses.includes(groupName)}
          onChange={(checked) => handleChange(groupName, checked)}
        >
          {t(`merchant_order_status.${subGroupList[0]}`)}
          {selectedStatuses.includes(tag[0]) ? <CheckOutlined /> : <PlusOutlined />}
        </Tag.CheckableTag>
      );
    });
  }, [selectedStatuses]);

  return (
    <Modal
      className='rounded-[20px] bg-white border border-customGrayShade3'
      title={
        <Flex
          className='font-bold text-lg leading-5  uppercase text-customTunaPrimary '
          style={{ borderBottom: '1px solid #E1E1E3', paddingBottom: '20px' }}
        >
          {t('export')}
        </Flex>
      }
      width={780}
      centered
      open={isOpen}
      footer={
        <Flex gap='16px' justify='end'>
          <Button onClick={resetModal}>
            <Heading
              leadingIcon={<CloseCircleIcon width={20} height={20} className='mt-1' />}
              title={t('clear_all').toUpperCase()}
            />
          </Button>
          <Button onClick={handleExport} type='primary' className='rounded-md'>
            {t('export').toUpperCase()}
          </Button>
        </Flex>
      }
      onClose={handleClose}
      onCancel={handleClose}
    >
      <Flex vertical gap='16px' className='mt-6'>
        <Typography.Text className='font-normal text-base leading-[20.48px] text-customTunaPrimary'>
          {t('by_status')}
        </Typography.Text>
        <Flex gap='16px' wrap>
          {renderStatusTags()}
        </Flex>
      </Flex>
      <Flex vertical gap='16px' className='w-1/2 mt-10 mb-10'>
        <Typography.Text className='font-normal text-base leading-[20.48px] text-customTunaPrimary'>
          {t('order_created_in')}
        </Typography.Text>
        <DatePicker.RangePicker
          className={`w-full custom-date-picker  border border-solid border-customGrayShade5 rounded-lg h-[46px]  ${i18n.language === LOCAL_EN ? 'pl-10' : 'pr-10'} text-center font-bold`} // Tailwind for styling the DatePicker
          format='YYYY-MM-DD'
          placeholder={[t('start_date'), t('end_date')]}
          showNow
          onChange={(dates) => {
            if (dates) setSelectedDateRange(dates as [Dayjs, Dayjs] | undefined);
          }}
          maxDate={dayjs()}
          value={selectedDateRange}
        />
        {/* <DatePicker.RangePicker
          picker='month'
          className={`w-full custom-date-picker  border border-solid border-customGrayShade5 rounded-lg h-[46px]  ${i18n.language === LOCAL_EN ? 'pl-10' : 'pr-10'} text-center font-bold`} // Tailwind for styling the DatePicker
          placeholder={[t('start_month'), t('end_month')]}
          showNow
          onChange={(dates) => {
            if (dates) setSelectedMonthRange(dates as [Dayjs, Dayjs] | undefined);
          }}
          value={selectedMonthRange}
        /> */}
      </Flex>
    </Modal>
  );
};
