import { create } from 'zustand';

export interface ISearch {
  search: string | null;
  searchBy: string | null;
}

const useSearchStore = create<{
  searchValue: ISearch;
  setSearchValue: (value: ISearch) => void;
}>((set) => ({
  searchValue: { search: null, searchBy: 'filter_platform_code' },
  setSearchValue: (value) => set({ searchValue: value }),
}));

export default useSearchStore;
