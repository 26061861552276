import { ISearch } from '@/hooks/useSearchStore';

export const createSearchParams = (
  page: number,
  pageSize?: number,
  search?: ISearch,
): URLSearchParams => {
  const params = new URLSearchParams({
    page: page.toString() || '1',
    perpage: (pageSize || 25).toString(),
  });

  if (search?.searchBy && search?.search) {
    params.append(search.searchBy, search.search);
  }

  return params;
};
